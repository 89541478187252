import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

import {
    VRow,
    VCol,
    VTextField,
    VTextarea,
    VTooltip,
    VCheckbox,
    VSelect,
} from 'vuetify/lib';
import {
    Ripple,
    Intersect,
    Touch,
    Resize
} from 'vuetify/lib/directives';


Vue.use(Vuetify, {
    components: {
        VRow,
        VTooltip,
        VCol,
        VTextField,
        VTextarea,
        VCheckbox,
        VSelect
    },
    directives: {
        Ripple,
        Intersect,
        Touch,
        Resize
    },
});

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#3898EC",
                secondary: "#7197d4",
                accent: "#82B1FF",
                error: "#FF5252",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#FFC107"
            }
        }
    },
    icons: {
        iconfont: "mdi"
    }
});