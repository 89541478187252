<template>
  <div>
    <div class="sperator"></div>
    <div class="main-body-section about-us">
      <div class="main-body-flex-block">
        <img src="@/assets/images/US-Map-11.png" alt />
        <div class="about-us-text-wrapper">
          <h2 class="left">Rocket Flood is super powered</h2>
          <p class="left">
            <strong
              >We are a super powered insurance agency specializing in personal
              and commercial flood insurance.</strong
            >
            Using a combination of technology, insurance expertise and customer
            service, we help clients secure flood insurance in 48 states across
            the country. Our mission is to make securing flood insurance an easy
            process and to deliver the most competitive pricing, so that more
            Americans protect themselves from our country’s most common natural
            disaster.
          </p>
          <p class="left">
            Up until recent years homeowners & business owners had very few
            options when looking to secure flood insurance and the majority of
            coverage was placed through FEMA’s National Flood Insurance Program.
          </p>
          <p class="left">
            Rocket Flood represents all viable flood insurance companies in the
            marketplace and we use our technology to quickly find the most
            competitive and protective option for your property.
          </p>
          <p class="left">
            As flood experts we are here to answer any questions you may have,
            educate you about flood insurance, and help you secure the best
            policy for your needs.
          </p>

          <button
            @click.stop="$router.push({ name: 'getAQuoteAddress' })"
            type="button"
            class="form-cta w-button"
          >
            Get A Quote Now
            <span class="cta-icon"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  head: {
    title: function () {
      return {
        inner: "About",
      };
    },
  },
  watch: {},
  created() {},
  methods: {},
};
</script>
