<template>
	<v-col
		class="selection-box"
		:cols="
			$vuetify.breakpoint.name == 'lg'
				? 2
				: $vuetify.breakpoint.name == 'md'
				? 2
				: $vuetify.breakpoint.name == 'sm'
				? 6
				: $vuetify.breakpoint.name == 'xs'
				? 6
				: 3
		"
	>
		<v-card @click="$emit('click')">
			<v-img
				contain
				v-if="imgSrc"
				:src="imgSrc"
				:max-height="
					['sm', 'xs'].includes($vuetify.breakpoint.name) ? '100px' : '130px'
				"
			></v-img>
			<v-card-text class="text-center">
				<div class="subtitle-1">
					<strong>{{ title }}</strong>
				</div>
				<div
					class="font-weight-light"
					style="font-size: 12px;"
					v-if="subtitle1 != ''"
				>
					{{ subtitle1 }}
					<template v-if="subtitle2 != ''">
						<br />
						{{ subtitle2 }}
					</template>
				</div>
			</v-card-text>
		</v-card>
	</v-col>
</template>

<script>
	export default {
		props: {
			imgSrc: {
				required: true,
				type: String
			},
			title: {
				required: true,
				type: String
			},
			subtitle1: {
				required: false,
				type: String
			},
			subtitle2: {
				required: false,
				type: String
			}
		},
		data() {
			return {};
		},
		computed: {}
	};
</script>

<style lang="scss">
	.selection-box {
		max-width: 310px !important;
		overflow: hidden;

		.v-card {
			border: 0.8px solid rgba(0, 0, 0, 0.3) !important;
			height: 100% !important;
			overflow: hidden;
		}
	}
	.selection-box:hover {
		animation-name: bounce;
		animation-duration: 0.5s;
	}

	@media (max-width: 767px) {
		.selection-box {
			.v-card {
				.v-card__text {
					padding: 0 !important;
					font-size: 10px;
					line-height: 15px;
				}
			}
		}
	}
</style>

