<template></template>

<script>
import axios from "axios";
export default {
  data: () => {
    return {};
  },
  head: {
    title: function () {
      return {
        inner: "Logout",
      };
    },
  },
  computed: {},
  watch: {},
  created() {
    if (this.$store.state.user) this.logout();
    else this.$router.push({ name: "login" });
  },
  methods: {
    logout() {
      var $this = this;
      let data = {
        session_key: this.$store.state.session_key,
      };
      this.post($this.baseUrl + "/logout", data, true).then((data) => {
        if (data == "undefined") return;
        this.$notify({
          group: "msg",
          title: data.error_obj ? data.message : "Message",
          text: data.error_obj
            ? Object.values(data.error_obj)[0][0]
            : data.message,
          type: data.status,
        });
        auth.logout();
        $this.$router.push({ name: "login" });
      });
    },
  },
};
</script>

<style lang="scss">
</style>
