var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"selection-box",attrs:{"cols":_vm.$vuetify.breakpoint.name == 'lg'
			? 2
			: _vm.$vuetify.breakpoint.name == 'md'
			? 2
			: _vm.$vuetify.breakpoint.name == 'sm'
			? 6
			: _vm.$vuetify.breakpoint.name == 'xs'
			? 6
			: 3}},[_c('v-card',{on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.imgSrc)?_c('v-img',{attrs:{"contain":"","src":_vm.imgSrc,"max-height":['sm', 'xs'].includes(_vm.$vuetify.breakpoint.name) ? '100px' : '130px'}}):_vm._e(),_c('v-card-text',{staticClass:"text-center"},[_c('div',{staticClass:"subtitle-1"},[_c('strong',[_vm._v(_vm._s(_vm.title))])]),(_vm.subtitle1 != '')?_c('div',{staticClass:"font-weight-light",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.subtitle1)+" "),(_vm.subtitle2 != '')?[_c('br'),_vm._v(" "+_vm._s(_vm.subtitle2)+" ")]:_vm._e()],2):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }