<template>
	<v-app>
		<!-- Message Dialog -->
		<Msg></Msg>
		<!-- Loading -->
		<Loading></Loading>
		<SpecialLoading></SpecialLoading>
		<!-- pages -->
		<router-view />
		<notifications group="msg" position="bottom center"></notifications>
		<DoNotSellMyInfoDialog></DoNotSellMyInfoDialog>
	</v-app>
</template>
<script>
	import Msg from "@/components/Msg";
	import Loading from "@/components/Loading";
	import SpecialLoading from "@/components/SpecialLoading";
	import DoNotSellMyInfoDialog from "@/components/dialogs/DoNotSellMyInfoDialog";

	export default {
		components: { Msg, Loading, SpecialLoading, DoNotSellMyInfoDialog },
		watch: {
			// currentPageNumber(val) {
			//   if (val == 8) {
			//     document.getElementById("chat-widget-container").style.display = "none";
			//   } else {
			//     document.getElementById("chat-widget-container").style.display =
			//       "block";
			//   }
			// },
		},
		mounted() {
			this.$nextTick(this.someoneVisited);
		},
		methods: {
			someoneVisited() {
				var url = this.baseUrl + "/someone-visited";
				var data = {
					starting_url: this.$store.state.starting_url
				};
				return this.post(url, data);
			}
		}
	};
</script>
