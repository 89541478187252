<template>
	<div>
		<div class="hero-section">
			<div class="flex-block-full-width-02">
				<h1 class="main-headline mt-n12">You Deserve Better Flood Insurance</h1>
				<p class="subhead">Get a Quote in under 3 minutes - Save up to 40%</p>
				<div class="cta-wrapper-02 my-2">
					<div class="form-block-01 w-form">
						<div id="email-form" class="form">
							<!-- <vue-google-autocomplete
								ref="address"
								id="map-top"
								class="text-field w-input"
								placeholder="Enter Address, City, State, Zip"
								v-on:placechanged="getAddressData"
								country="us"
							></vue-google-autocomplete> -->
							<Autocomplete
								@searched_address="val => (inputAddress = val)"
							></Autocomplete>
							<!-- @click="$router.push({ name: 'getAQuoteAddress' })" -->
							<input
								@click="inputAddress ? addressAdded() : null"
								type="button"
								value="Get A Quote"
								class="form-cta w-button"
							/>
						</div>
						<a href="#" class="hero-cta-text-link hide">
							<span class="cta-video-icon"></span> Watch the video
							<span class="cta-icon"></span>
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="sperator hide"></div>
		<div class="review-section">
			<div class="review-headline-flex-block">
				<h2 class="heading-5 main-headline">All Carriers A+ Rated</h2>
			</div>
			<div class="review-flex-block">
				<div class="reviews-section-logos-col">
					<img
						src="@/assets/images/am-best-a-rating3a6853b1d3c9616ab66fff000080cfd4.png"
						srcset="
							images/am-best-a-rating3a6853b1d3c9616ab66fff000080cfd4-p-500.png 500w,
							images/am-best-a-rating3a6853b1d3c9616ab66fff000080cfd4.png       509w
						"
						sizes="(max-width: 479px) 120px, (max-width: 767px) 170px, (max-width: 991px) 280px, 350px"
						alt
						class="logo-icon-one"
					/>
					<img
						src="@/assets/images/Demotech_Seal.png"
						alt
						class="logo-icon-2"
					/>
				</div>
			</div>
			<TestimonialCarousel></TestimonialCarousel>
		</div>
		<div class="sperator"></div>
		<div class="logos-section img-r">
			<div class="logos-section-flex-block-02">
				<h2 class="heading-5" style="color: #4a4a4a">Our Mission is Simple:</h2>
				<p>
					Provide the best possible coverage while saving our clients Time and
					Money
				</p>
			</div>
			<div class="logos-section-flex-block-04">
				<div class="logo-col">
					<div class="val-prop-icon-col">
						<div class="icon-bg icon-01"></div>
					</div>
					<div class="value-prop-text-col">
						<p class="icon-heading" style="color: #4a4a4a">
							Most Competitive Rates – Because Price Matters
						</p>
						<p>
							Our clients save up to 40% on their premiums
							<br />
						</p>
						<p class="small-icon-p">
							Rocket Flood utilizes LiDAR technology – the same tech used by
							NASA and the U.S. Government. These highly detailed images of the
							terrain provide elevation data points, used as elevation discounts
							on your policy.
						</p>
						<p class="small-icon-p">
							We have partnered with every major flood insurance company across
							the country and our powerful technology shops the entire
							marketplace to find you the most competitive policy possible. On
							average our clients save 40% on their premiums compared to using
							an old fashion and less effective “local agent”.
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="sperator hide"></div>
		<div class="logos-section img-l">
			<div class="logos-section-flex-block-04">
				<div class="logo-col">
					<div class="value-prop-text-col left">
						<p class="icon-heading" style="color: #4a4a4a">
							Fast, Hassle Free Process
						</p>
						<p>
							the fastest, easiest flood insurance process… ever
							<br />
						</p>
						<p class="small-icon-p left-text">
							Traditional insurance can be slow, cumbersome and costly. Rocket
							Flood is changing all of that. With our superior technology and
							streamlined process we don’t exhaust you with long forms to fill
							out… Simply type your address and answer a few quick questions to
							secure the best flood coverage available!
						</p>
					</div>
					<div class="val-prop-icon-col first">
						<div class="icon-bg icon-02"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="sperator hide"></div>
		<div class="logos-section img-r _3">
			<div class="logos-section-flex-block-04">
				<div class="logo-col">
					<div class="val-prop-icon-col">
						<div class="icon-bg icon-03"></div>
					</div>
					<div class="value-prop-text-col">
						<p class="icon-heading" style="color: #4a4a4a">Superior Coverage</p>
						<p>
							Time & Money isn’t the only Benefit
							<br />
						</p>
						<p class="small-icon-p">
							Our programs can provide coverage up to $20,000,000 for building
							and up to $5,000,000 for personal contents. In addition to these
							increased limits Rocket Flood also offers coverage enhancements
							such as Replacement Cost on Contents, Loss of Use, Loss of Rents,
							Other Structure Coverage, Basement Contents, and Pool
							Repair/Refill.
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import goTo from "vuetify/lib/services/goto";
	import TestimonialCarousel from "@/components/TestimonialCarousel";
	import Autocomplete from "@/components/address-autocomplete/Autocomplete";
	// import VueGoogleAutocomplete from "vue-google-autocomplete";
	export default {
		components: {
			TestimonialCarousel,
			Autocomplete
			// VueGoogleAutocomplete
		},
		data() {
			return {
				inputAddress: "",
				addressList: []
			};
		},
		head: {
			title: function() {
				return {
					inner: "Home"
				};
			},
			meta: [{ name: "description", content: "RocketFlood", id: "desc" }]
		},
		props: {
			headerSmall: { required: false, default: false, type: Boolean }
		},
		watch: {
			// inputAddress(val) {
			// 	console.log(val);
			// },
		},
		mounted() {
			goTo(0);
		},
		methods: {
			getAddressData: function(addressData, placeResultData, id) {
				this.inputAddress = placeResultData.formatted_address;
			},
			async addressAdded() {
				let inputAddress = this.inputAddress;
				// let inputAddressArr = inputAddress.split(",");
				// let arr = [inputAddressArr.shift(), inputAddressArr.join(",")];

				// let address1 = arr[0]?.trim() || this.inputAddress;
				// let address2 = arr[1]?.trim() || "";

				// console.log(this.inputAddress);
				// console.log(inputAddress);
				// console.log(address1, address2);

				if (this.inputAddress) {
					this.$store.state.data.application.address = this.inputAddress;
					this.getPropertyDetailsFromAttomApi(inputAddress).then(val => {
						// console.log(val && val.code == 200 && !!val.data.data);
						if (val && val.code == 200 && !!val.data.data) {
							let details = val?.data?.data || {};
							let useable_details = val?.data?.useable_data || {};
							let applicationDefaults = { ...this.$store.state.data.application };

							this.$store.state.data.application = Object.assign(
								applicationDefaults,
								useable_details
							);

							this.$store.state.data.application["constructionType"] = details
								.building.construction.frameType
								? `${details.building.construction.frameType.toLowerCase()}`
								: "";

							if (
								![null, ""].includes(useable_details.addr1) &&
								![null, ""].includes(useable_details.city) &&
								![null, ""].includes(useable_details.state) &&
								![null, ""].includes(useable_details.zip)
							) {
								this.$store.state.data.application.home_detail_received_from_api = true;
							}
						}
						this.$router.push({ name: "getAQuoteAddress" });
					});
				} else {
					this.$router.push({ name: "getAQuoteAddress" });
				}

				// console.log(length, country, state, city, line);
				// this.$router.push({ name: "getAQuoteAddress" });
			}
		}
	};
</script>


<style lang="scss">
	@media (max-width: 767px) {
		.logos-section {
			background-image: none !important;
			.logos-section-flex-block-04 {
				.logo-col {
					.value-prop-text-col {
						padding-bottom: 0 !important;
					}
				}
			}
		}
	}
</style>

