/* eslint-disable prettier/prettier */
import store from '../store';

class Auth {
    constructor() {
        this.session_key = null;
        this.user = null;
        this.user_role = null;
        this.currentMode = null;
    }

    initAuth() {
        if (this.isLogin()) {
            this.session_key = window.localStorage.getItem('session_key');
            this.user = window.localStorage.getItem('user');
            this.user_role = window.localStorage.getItem('user_role');
            this.currentMode = window.localStorage.getItem('currentMode');
            this.login(this.session_key, this.user, this.user_role, this.currentMode);
            return;
        }
        this.logout();
        return;
    }

    login(session_key, user, user_role, currentMode = '') {
        this.session_key = session_key;
        this.user = user;
        this.user_role = user_role;
        this.currentMode = currentMode;

        if (!this.isLogin()) {
            if (user_role == 'admin') {
                this.currentMode = 'admin';
            } else if (user_role == 'employee') {
                this.currentMode = 'admin';
            } else if (user_role.includes('buyer')) {
                if (user.buyer.is_approved == true) {
                    this.currentMode = 'buyer';
                } else if (user.seller.is_approved == true) {
                    this.currentMode = 'seller';
                } else {
                    this.currentMode = 'notApproved';
                }
            } else if (user_role.includes('seller')) {
                if (user.seller.is_approved == true) {
                    this.currentMode = 'seller';
                } else if (user.buyer.is_approved == true) {
                    this.currentMode = 'buyer';
                } else {
                    this.currentMode = 'notApproved';
                }
            }

            // if (user.profile_image == null)
            //     this.user.profile_image = "/profile_images/profile-placeholder.png";

            window.localStorage.setItem('session_key', this.session_key);
            window.localStorage.setItem('user', JSON.stringify(this.user));
            window.localStorage.setItem('user_role', this.user_role);
            window.localStorage.setItem('currentMode', this.currentMode);
        }

        store.dispatch('login', {
            "session_key": this.session_key,
            "user": this.user,
            "user_role": this.user_role,
            "currentMode": this.currentMode
        });
    }

    logout() {
        this.session_key = null;
        this.user_role = null;
        this.user = null;
        this.currentMode = null;
        window.localStorage.setItem('session_key', null);
        window.localStorage.setItem('user', null);
        window.localStorage.setItem('user_role', null);
        window.localStorage.setItem('currentMode', null);
        store.dispatch('logout');
    }

    isLogin() {
        if (window.localStorage.getItem('session_key') === "undefined" ||
            window.localStorage.getItem('session_key') == "null" ||
            window.localStorage.getItem('session_key') == null) {
            return false;
        }
        return true;
    }

}

export default new Auth();