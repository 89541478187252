<template>
	<div>
		<!-- Header -->
		<div class="section">
			<div
				class="html-embed-5 w-embed"
				style="background-color: white; z-index: 99; top: 5px; left: 2px"
				@click="pageBack()"
			>
				<span>
					<i class="far fa-arrow-alt-circle-left fa-3x"></i>
				</span>
			</div>
			<div
				style="top: 10px; position: relative; opacity: 0"
				:style="
					['sm', 'xs'].includes($vuetify.breakpoint.name)
						? 'width: 70px;'
						: 'width: 167px;'
				"
			></div>
			<v-spacer></v-spacer>
			<img
				@click="back()"
				:src="'/images/5d9a33664aefbeb0b6970a93_RocketFlood-Logo.svg'"
				class="image-1 py-3 px-0"
				style="cursor: pointer"
				:style="
					['sm', 'xs'].includes($vuetify.breakpoint.name)
						? 'max-width:160px;'
						: 'max-width:360px;'
				"
				alt="logo"
			/>
			<v-spacer></v-spacer>
			<a
				:href="`tel:${companyPhoneNumber}`"
				@click.prevent.stop="openCallNow()"
			>
				<div
					class="nav-link-agent"
					style="display: inline-block !important; min-height: 50px"
				>
					<div
						class="header-phone-number"
						style="
							display: block !important;
							opacity: 1;
							color: #4a4a4a !important;
						"
						:style="
							['sm', 'xs'].includes($vuetify.breakpoint.name)
								? 'display:none !important;'
								: ''
						"
					>
						{{ companyPhoneNumber }}
					</div>
				</div>
			</a>
		</div>

		<!-- Form Progress Bar -->
		<div class="section-2">
			<div
				:class="
					['1', '2', '3', '4', '5', '6', '7', '8'].includes(
						Math.floor(currentPageNumber).toString()
					)
						? 'div-block-9'
						: 'div-block-9-white'
				"
			></div>
			<div
				:class="
					['2', '3', '4', '5', '6', '7', '8'].includes(
						Math.floor(currentPageNumber).toString()
					)
						? 'div-block-9'
						: 'div-block-9-white'
				"
			></div>
			<div
				:class="
					['3', '4', '5', '6', '7', '8'].includes(
						Math.floor(currentPageNumber).toString()
					)
						? 'div-block-9'
						: 'div-block-9-white'
				"
			></div>
			<div
				:class="
					['4', '5', '6', '7', '8'].includes(
						Math.floor(currentPageNumber).toString()
					)
						? 'div-block-9'
						: 'div-block-9-white'
				"
			></div>
			<div
				:class="
					['5', '6', '7', '8'].includes(
						Math.floor(currentPageNumber).toString()
					)
						? 'div-block-9'
						: 'div-block-9-white'
				"
			></div>
			<div
				:class="
					['6', '7', '8'].includes(Math.floor(currentPageNumber).toString())
						? 'div-block-9'
						: 'div-block-9-white'
				"
			></div>
			<div
				:class="
					['7', '8'].includes(Math.floor(currentPageNumber).toString())
						? 'div-block-9'
						: 'div-block-9-white'
				"
			></div>
			<div
				:class="
					['8'].includes(Math.floor(currentPageNumber).toString())
						? 'div-block-9'
						: 'div-block-9-white'
				"
			></div>
		</div>

		<!-- page content -->
		<router-view />

		<!-- Footer -->
		<!-- <div class="footer-section">
      <div class="footer-flex-block">
        <div class="footer-col-01">
          <img src="/images/5d9be876787cd06ffb3d04d2_Reverse-Logo.svg" alt />
        </div>
        <div class="footer-col">
          <a href="index.html" class="footer-link w-inline-block w--current">
            <div>Home</div>
          </a>
          <a href="about-us.html" class="footer-link w-inline-block">
            <div>About Us</div>
          </a>
          <a href="faq.html" class="footer-link w-inline-block">
            <div>FAQs</div>
          </a>
          <a href="#" class="footer-link w-inline-block">
            <div>Request A Quote</div>
          </a>
        </div>
        <div class="footer-col">
          <p class="footer-p-rename">Address</p>
          <p class="footer-p">12345 Lorem ipsum Dr. West Palm Beach Fl. 33407</p>
          <p class="footer-p-rename">Phone Number</p>
          <p class="footer-p">000-000-0000</p>
        </div>
      </div>
      <div class="footer-flex-block-02">
        <div class="bottom-footer-col">
          <p class="bottom-footer-p">
            Copyright © 2019. Rocket Flood. All rights reserved.
            <span class="hide">|</span>
            <a href="#" class="footer-text-link hide">Privacy Policy</a>
          </p>
        </div>
      </div>
    </div>-->
	</div>
</template>

<script>
	import { mapState } from "vuex";

	export default {
		components: {},
		data: () => {
			return {};
		},
		computed: {
			...mapState(["data", "responses", "currentPageNumber"])
		},
		watch: {
			currentPageNumber(newVal, oldVal) {
				if (newVal != oldVal) {
					this.$vuetify.goTo(0, {
						duration: 300,
						offset: 0,
						easing: "easeInOutCubic"
					});
				}
			}
		},
		mounted() {
			this.$vuetify.goTo(0, {
				duration: 300,
				offset: 0,
				easing: "easeInOutCubic"
			});
		},
		methods: {
			openCallNow() {
				return (window.location.href = `tel:${this.companyPhoneNumber}`);
			}
		}
	};
</script>
