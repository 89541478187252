<template>
	<div :class="`currentPageNumber-${currentPageNumber}`">
		<!-- Page 0 -->
		<div
			class="mb-12 mt-5 px-6"
			:class="['sm', 'xs'].includes($vuetify.breakpoint.name) ? 'mb-12' : ''"
			v-if="currentPageNumber == 0"
		>
			<div class="section-3 my-0">
				<h1 class="heading-8 my-0">Continue as:</h1>
			</div>
			<v-container class="d-flex my-1">
				<v-row
					align="stretch"
					style="width: 100%"
					:justify="
						['sm', 'xs'].includes($vuetify.breakpoint.name) ? 'start' : 'center'
					"
				>
					<SelectionBox
						:imgSrc="`/images/home-owner.png`"
						title="Homeowner / Prospective Buyer"
						subtitle1=""
						subtitle2=""
						@click="
							($store.state.data.application.application_submitter_type =
								'homeowner'),
								goToAddressDetailsFormOrNext()
						"
					></SelectionBox>

					<SelectionBox
						:imgSrc="`/images/home-agent.png`"
						title="Real Estate / Mortgage Professional"
						subtitle1=""
						subtitle2=""
						@click="
							($store.state.data.application.application_submitter_type =
								'agent'),
								(currentPageNumber = 0.1)
						"
					></SelectionBox>
				</v-row>
			</v-container>
		</div>

		<!-- Page 0.1 -->
		<div
			class="my-3 mt-5 px-6"
			:class="['sm', 'xs'].includes($vuetify.breakpoint.name) ? 'mb-12' : ''"
			v-if="currentPageNumber == 0.1"
		>
			<div class="section-3 pa-0">
				<h1
					:style="
						['sm', 'xs'].includes($vuetify.breakpoint.name)
							? 'font-size:0.8rem;'
							: ''
					"
					class="heading-8 my-0 pa-0"
				>
					Please provide your clients name:
				</h1>
			</div>
			<div class="section-4-copy my-1">
				<v-form
					v-model="isFormValid"
					ref="form"
					style="width: 100%; max-width: 600px"
				>
					<v-row class="pa-0 ma-0 mt-10">
						<v-col cols="12" md="6" class="pa-2 ma-0">
							<div class="field-label">
								First Name
								<span class="text-span">*</span>
							</div>
							<v-text-field
								class="new_firstNamepa-0 ma-0"
								v-model="$store.state.data.application.new_firstName"
								:rules="[rules.requireField]"
								outlined
								dense
								ref="new_firstName"
								:hide-details="!fieldHasError('new_firstName')"
							></v-text-field>
						</v-col>
						<v-col cols="12" md="6" class="pa-2 ma-0">
							<div class="field-label">
								Last Name
								<span class="text-span">*</span>
							</div>
							<v-text-field
								class="new_lastName"
								v-model="$store.state.data.application.new_lastName"
								:rules="[rules.requireField]"
								outlined
								dense
								ref="new_lastName"
								:hide-details="!fieldHasError('new_lastName')"
							></v-text-field>
						</v-col>
						<v-col class="mt-10">
							<!-- @click="currentPageNumber = 8" -->
							<v-btn
								large
								block
								@click="goToAddressDetailsFormOrNext()"
								color="primary"
								>CONTINUE</v-btn
							>
						</v-col>
					</v-row>
				</v-form>
			</div>
		</div>

		<!-- Page 1  -->
		<div
			class="mb-12 mt-4 px-6"
			:class="['sm', 'xs'].includes($vuetify.breakpoint.name) ? 'mb-12' : ''"
			v-if="currentPageNumber == 1"
		>
			<div class="section-3 my-0">
				<h1 class="heading-8 my-0">
					We need some more details about your address
				</h1>
				<p class="paragraph">Please retype your address below</p>
				<div
					style="
						width: 100%;
						max-width: 800px;
						left: 0;
						right: 0;
						margin: 0 auto;
					"
				>
					<v-form ref="form" v-model="isFormValid">
						<v-row>
							<v-col cols="12" md="6">
								<div class="field-label">
									Address 1
									<span class="text-span">*</span>
								</div>
								<v-text-field
									class="addr1"
									v-model="$store.state.data.application.addr1"
									:rules="[rules.requireField]"
									outlined
									ref="addr1"
									:hide-details="!fieldHasError('addr1')"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="6">
								<div class="field-label">Address 2</div>
								<v-text-field
									class="addr2"
									v-model="$store.state.data.application.addr2"
									ref="addr2"
									:hide-details="!fieldHasError('addr2')"
									outlined
								></v-text-field>
							</v-col>
							<v-col cols="12" md="4">
								<div class="field-label">
									City
									<span class="text-span">*</span>
								</div>
								<v-text-field
									class="city"
									v-model="$store.state.data.application.city"
									:rules="[rules.requireField]"
									outlined
									ref="city"
									:hide-details="!fieldHasError('city')"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="4">
								<div class="field-label">
									State
									<span class="text-span">*</span>
								</div>
								<v-select
									class="state"
									v-model="$store.state.data.application.state"
									:rules="[rules.requireField]"
									:items="states"
									item-text="name"
									item-value="abbreviation"
									ref="state"
									:hide-details="!fieldHasError('state')"
									outlined
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<div class="field-label">
									Zip
									<span class="text-span">*</span>
								</div>
								<v-text-field
									outlined
									ref="zip"
									class="zip"
									v-model="$store.state.data.application.zip"
									:rules="[rules.requireField]"
									:hide-details="!fieldHasError('zip')"
								></v-text-field>
							</v-col>
							<v-col class="mt-4" cols="12">
								<v-btn
									:disabled="
										$store.state.data.application.do_not_sell_my_info == true
									"
									large
									block
									@click="locationInfoFormSubmit"
									color="primary"
								>
									Start Quote
								</v-btn>
							</v-col>
							<v-col
								class="mt-4 orange--text"
								cols="12"
								v-if="$store.state.data.application.do_not_sell_my_info == true"
							>
								<p>
									You have chosen to opt out of having your information sold.
								</p>
								<p
									class="primary--text text-decoration-underline"
									@click="
										$store.state.data.application.do_not_sell_my_info_dialog = true
									"
								>
									Manage Preferences
								</p>
							</v-col>
						</v-row>
					</v-form>
				</div>
			</div>
		</div>

		<!-- Page 2 -->
		<div
			class="mb-12 mt-5 px-6"
			:class="['sm', 'xs'].includes($vuetify.breakpoint.name) ? 'mb-12' : ''"
			v-if="currentPageNumber == 2"
		>
			<div class="section-3 my-0">
				<h1 class="heading-8 my-0">What kind of home do you have?</h1>
			</div>
			<v-container class="d-flex my-1">
				<v-row
					align="stretch"
					style="width: 100%"
					:justify="
						['sm', 'xs'].includes($vuetify.breakpoint.name) ? 'start' : 'center'
					"
				>
					<SelectionBox
						:imgSrc="'/images/home.png'"
						title="Home"
						subtitle1="Single Family, Multi-Family,"
						subtitle2="Townhouse, Etc."
						@click="
							($store.state.data.application.occupancy = 'singleFamily'),
								(currentPageNumber = 3)
						"
					></SelectionBox>

					<SelectionBox
						:imgSrc="'/images/condo.png'"
						title="Condo"
						subtitle1="Co-op or Apartment"
						subtitle2=""
						@click="
							($store.state.data.application.occupancy = 'condo'),
								(currentPageNumber = 2.1)
						"
					></SelectionBox>

					<SelectionBox
						:imgSrc="'/images/mobile.png'"
						title="Manufactured"
						subtitle1="Or Mobile Home"
						subtitle2=""
						@click="
							($store.state.data.application.occupancy = 'mobile'),
								(currentPageNumber = 3)
						"
					></SelectionBox>
				</v-row>
			</v-container>
		</div>

		<!-- page 2.1 -->
		<div
			class="mb-12 mt-5 px-6"
			:class="['sm', 'xs'].includes($vuetify.breakpoint.name) ? 'mb-12' : ''"
			v-if="currentPageNumber == 2.1"
		>
			<div class="section-3 my-0">
				<h1 class="heading-8 my-0">What floor your condo located on?</h1>
			</div>
			<v-container class="d-flex my-6">
				<v-row align="stretch" justify="center">
					<v-col
						:cols="
							$vuetify.breakpoint.name == 'md'
								? 6
								: $vuetify.breakpoint.name == 'sm'
								? 6
								: $vuetify.breakpoint.name == 'xs'
								? 12
								: 4
						"
					>
						<v-form v-model="isFormValid" ref="form">
							<v-select
								:rules="[rules.requireField]"
								@change="submitContoFloorForm()"
								:class="
									`condoFloor-${$store.state.data.application.condoFloor}`
								"
								v-model="$store.state.data.application.condoFloor"
								label="Floor Number*"
								:items="[
									'01',
									'02',
									'03',
									'04',
									'05',
									'06',
									'07',
									'08',
									'09',
									'10 Or Higher'
								]"
								outlined
							></v-select>
						</v-form>
					</v-col>
				</v-row>
			</v-container>
		</div>

		<!-- Page 3 -->
		<div
			class="mb-12 mt-5 px-6"
			:class="['sm', 'xs'].includes($vuetify.breakpoint.name) ? 'mb-12' : ''"
			v-if="currentPageNumber == 3"
		>
			<div v-if="$store.state.data.application.occupancy == 'singleFamily'">
				<div class="section-3 my-0">
					<h1 class="heading-8 my-0">What is your foundation type?</h1>
				</div>
				<v-container class="d-flex justify-center my-0">
					<v-row
						align="stretch"
						style="width: 100%"
						:justify="
							['sm', 'xs'].includes($vuetify.breakpoint.name)
								? 'start'
								: 'center'
						"
					>
						<SelectionBox
							:imgSrc="'/images/home1.png'"
							title="Home On Slab"
							subtitle1=""
							subtitle2=""
							@click="
								($store.state.data.application.foundationType = 'slab'),
									(currentPageNumber = 3.1)
							"
						></SelectionBox>
						<SelectionBox
							:imgSrc="'/images/home2.png'"
							title="Home On Crawl Space"
							subtitle1=""
							subtitle2=""
							@click="
								($store.state.data.application.foundationType = 'crawlspace'),
									(currentPageNumber = 4)
							"
						></SelectionBox>
						<SelectionBox
							:imgSrc="'/images/home3.png'"
							title="Home with basement"
							subtitle1=""
							subtitle2=""
							@click="currentPageNumber = 3.2"
						></SelectionBox>
						<SelectionBox
							:imgSrc="'/images/home4.png'"
							title="Home On Stilts, Pilings, or Enclosure"
							subtitle1=""
							subtitle2=""
							@click="currentPageNumber = 3.3"
						></SelectionBox>
						<SelectionBox
							:imgSrc="'/images/home5.png'"
							title="Home Entirely Over Garage"
							subtitle1=""
							subtitle2=""
							@click="currentPageNumber = 3.4"
						></SelectionBox>
					</v-row>
				</v-container>
			</div>
			<div v-if="$store.state.data.application.occupancy == 'condo'">
				<div class="section-3">
					<h1 class="heading-8">
						True Ground Level or First Floor Above Lobby/Garage?
					</h1>
				</div>
				<v-container class="d-flex justify-center my-12">
					<v-row
						align="stretch"
						style="width: 100%"
						:justify="
							['sm', 'xs'].includes($vuetify.breakpoint.name)
								? 'start'
								: 'center'
						"
					>
						<v-col class="selection-box" cols="12" md="3" sm="4">
							<v-card
								class="condoFloor-1"
								@click="
									(($store.state.data.application.foundationType = 'slab'),
									($store.state.data.application.condoFloor = 1)),
										(currentPageNumber = 4)
								"
							>
								<v-img
									contain
									src="@/assets/images/condo1.png"
									max-height="200"
								></v-img>
								<v-card-text class="text-center">
									<div class="subtitle-1">
										<strong>First Floor</strong>
									</div>
									<div class="font-weight-light">Ground Level</div>
								</v-card-text>
							</v-card>
						</v-col>
						<v-col class="selection-box" cols="12" md="3" sm="4">
							<v-card
								class="condoFloor-2"
								@click="
									(($store.state.data.application.foundationType = 'slab'),
									($store.state.data.application.condoFloor = 2)),
										(currentPageNumber = 4)
								"
							>
								<v-img
									contain
									src="@/assets/images/condo2.png"
									max-height="200"
								></v-img>
								<v-card-text class="text-center">
									<div class="subtitle-1">
										<strong>First Floor</strong>
									</div>
									<div class="font-weight-light">
										Above Garage, Stilts or Pilings
									</div>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>
				</v-container>
			</div>
			<div v-if="$store.state.data.application.occupancy == 'mobile'">
				<v-container class="d-flex justify-center my-12">
					<v-row align="stretch" justify="center">
						<v-col cols="7">
							<v-card class="transparent" elevation="0">
								<h1 class="heading-8">We are sorry,</h1>
								<v-card-text class="text-center heading">
									We apologize, mobile home flood quote are not currently
									available through the website. Please call us at
									{{ companyPhoneNumber }}
									to obtain a quote.
								</v-card-text>
								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn large color="primary">
										<a
											style="color: white; font-size: 18px"
											:href="`tel:${companyPhoneNumber}`"
											>Call Now</a
										>
										<v-icon>mdi-phone</v-icon>
									</v-btn>
									<v-spacer></v-spacer>
								</v-card-actions>
							</v-card>
						</v-col>
					</v-row>
				</v-container>
			</div>
		</div>

		<!-- Page 3.1 -->
		<div
			class="mb-12 mt-5 px-6"
			:class="['sm', 'xs'].includes($vuetify.breakpoint.name) ? 'mb-12' : ''"
			v-if="currentPageNumber == 3.1"
		>
			<div class="section-3 my-0">
				<h1 class="heading-8 my-0">
					How many steps leads to your front door of your property?
				</h1>
			</div>
			<v-container class="d-flex justify-center mb-12 mt-0">
				<v-row
					align="stretch"
					style="width: 100%"
					:justify="
						['sm', 'xs'].includes($vuetify.breakpoint.name) ? 'start' : 'center'
					"
				>
					<SelectionBox
						:imgSrc="'/images/door-steps1.png'"
						title="No Steps"
						subtitle1=""
						subtitle2=""
						@click="
							(currentPageNumber = 4),
								($store.state.data.application.numberOfSteps = 0)
						"
					></SelectionBox>
					<SelectionBox
						:imgSrc="'/images/door-steps2.png'"
						title="1 Step"
						subtitle1=""
						subtitle2=""
						@click="
							(currentPageNumber = 4),
								($store.state.data.application.numberOfSteps = 1)
						"
					></SelectionBox>
					<SelectionBox
						:imgSrc="'/images/door-steps3.png'"
						title="2 Step"
						subtitle1=""
						subtitle2=""
						@click="
							(currentPageNumber = 4),
								($store.state.data.application.numberOfSteps = 2)
						"
					></SelectionBox>
					<SelectionBox
						:imgSrc="'/images/door-steps4.png'"
						title="3 steps or greater"
						subtitle1=""
						subtitle2=""
						@click="
							(currentPageNumber = 4),
								($store.state.data.application.numberOfSteps = 3)
						"
					></SelectionBox>
				</v-row>
			</v-container>
		</div>

		<!-- Page 3.2 -->
		<div
			class="mb-12 mt-5 px-6"
			:class="['sm', 'xs'].includes($vuetify.breakpoint.name) ? 'mb-12' : ''"
			v-if="currentPageNumber == 3.2"
		>
			<div class="section-3 my-0">
				<h1 class="heading-8 my-0">What kind of basement do you have?</h1>
			</div>
			<v-container class="d-flex justify-center my-0">
				<v-row
					align="stretch"
					style="width: 100%"
					:justify="
						['sm', 'xs'].includes($vuetify.breakpoint.name) ? 'start' : 'center'
					"
				>
					<SelectionBox
						:imgSrc="'/images/basement1.png'"
						title="Finished Basement"
						subtitle1="Contains 20+ linear ft. of finished walls. Finished walls are considered walls with drywall, spackle, and paint."
						subtitle2=""
						@click="
							($store.state.data.application.foundationType =
								'finishedBasement'),
								(currentPageNumber = 4)
						"
					></SelectionBox>
					<SelectionBox
						:imgSrc="'/images/basement2.png'"
						title="Unfinished Basement"
						subtitle1="Basement space is unfinished. (i.e cement block and concrete
                    floors)"
						subtitle2=""
						@click="
							($store.state.data.application.foundationType =
								'unfinishedBasement'),
								(currentPageNumber = 4)
						"
					></SelectionBox>
				</v-row>
			</v-container>
		</div>

		<!-- Page 3.3 -->
		<div
			class="mb-12 mt-5 px-6"
			:class="['sm', 'xs'].includes($vuetify.breakpoint.name) ? 'mb-12' : ''"
			v-if="currentPageNumber == 3.3"
		>
			<div class="section-3 my-0">
				<h1 class="heading-8 my-0">Which home is most like yours?</h1>
			</div>
			<v-container class="d-flex justify-center my-6">
				<v-row
					align="stretch"
					style="width: 100%"
					:justify="
						['sm', 'xs'].includes($vuetify.breakpoint.name) ? 'start' : 'center'
					"
				>
					<SelectionBox
						:imgSrc="'/images/ground-floor3.png'"
						title="Completely Open Ground Floor Level"
						subtitle1="All buildings elevated on piers, posts, piles, columns, or parallel shear walls. "
						subtitle2="No obstructions below the elevated floor."
						@click="
							($store.state.data.application.foundationType = 'stiltsWithOpen'),
								(currentPageNumber = 4)
						"
					></SelectionBox>
					<SelectionBox
						:imgSrc="'/images/ground-floor2.png'"
						title="Unfinished Ground Floor Level"
						subtitle1="All buildings elevated on piers, posts, or piles with full or partial enclosure below the elevated floor. "
						subtitle2="Enclosed space is completely unfinished."
						@click="
							($store.state.data.application.foundationType =
								'stiltsWithSolidEnclosure'),
								(currentPageNumber = 4)
						"
					></SelectionBox>
					<SelectionBox
						:imgSrc="'/images/ground-floor1.png'"
						title="Finished Ground Floor Level"
						subtitle1="Enclosure contains 20+ linear ft. of finished walls. "
						subtitle2="Finished walls are considered walls with drywall, spackle, and paint."
						@click="
							($store.state.data.application.foundationType =
								'stiltsWithVents'),
								(currentPageNumber = 4)
						"
					></SelectionBox>
				</v-row>
			</v-container>
		</div>

		<!-- Page 3.4 -->
		<div
			class="mb-12 mt-5 px-6"
			:class="['sm', 'xs'].includes($vuetify.breakpoint.name) ? 'mb-12' : ''"
			v-if="currentPageNumber == 3.4"
		>
			<div class="section-3 my-0">
				<h1 class="heading-8 my-0">Which home is most like yours?</h1>
			</div>
			<v-container class="d-flex justify-center my-6">
				<v-row
					align="stretch"
					style="width: 100%"
					:justify="
						['sm', 'xs'].includes($vuetify.breakpoint.name) ? 'start' : 'center'
					"
				>
					<SelectionBox
						:imgSrc="'/images/garage1.png'"
						title="Garage Finished"
						subtitle1="Contains 20+ linear ft. of finished walls."
						subtitle2="Finished walls are considered walls with drywall, spackle, and paint."
						@click="
							($store.state.data.application.foundationType =
								'garageWithNonElevatedUtilities'),
								(currentPageNumber = 4)
						"
					></SelectionBox>

					<SelectionBox
						:imgSrc="'/images/garage2.png'"
						title="Garage Unfinished"
						subtitle1="No Finished space on ground level."
						subtitle2=""
						@click="
							($store.state.data.application.foundationType =
								'unfinishedGarage'),
								(currentPageNumber = 4)
						"
					></SelectionBox>
				</v-row>
			</v-container>
		</div>

		<!-- Page 4-->
		<div
			class="my-3 mt-5 px-6"
			:class="['sm', 'xs'].includes($vuetify.breakpoint.name) ? 'mb-12' : ''"
			v-if="currentPageNumber == 4"
		>
			<div class="section-3 mb-0 mt-1 pb-0">
				<h1 class="heading-8 my-0">Property Information</h1>
				<div class="text-block-1 my-0 mt-2">
					Take a look and modify anything if needed.
				</div>
			</div>
			<div
				style="width: 100%; max-width: 600px; left: 0; right: 0; margin: 0 auto"
				class="px-6"
			>
				<v-form v-model="isFormValid" ref="form">
					<v-row>
						<v-col
							class="ma-0 pa-0 mt-1 mb-2"
							cols="12"
							md="12"
							v-if="$store.state.data.application.occupancy == 'condo'"
						>
							<div class="field-label">
								Unit / Apt Number
								<span class="text-span">*</span>
							</div>
							<v-text-field
								class="addr2"
								v-model="$store.state.data.application.addr2"
								:rules="[rules.requireField]"
								outlined
								dense
								ref="addr2"
								:hide-details="!fieldHasError('addr2')"
							></v-text-field>
						</v-col>
						<v-col class="ma-0 pa-0 mt-1 mb-2" cols="12" md="12">
							<div class="field-label">
								Year Built
								<span class="text-span">*</span>
							</div>
							<v-text-field
								class="yearBuilt"
								v-model="$store.state.data.application.yearBuilt"
								:rules="[rules.requireField, rules.validYear]"
								type="number"
								outlined
								small
								dense
								ref="yearBuilt"
								:hide-details="!fieldHasError('yearBuilt')"
							></v-text-field>
						</v-col>
						<v-col class="ma-0 pa-0 mt-1 mb-2" cols="12">
							<div class="field-label">
								Sqft
								<span class="text-span">*</span>
							</div>
							<v-text-field
								:class="`sqft-${$store.state.data.application.sqft}`"
								v-model="$store.state.data.application.sqft"
								:rules="[rules.requireField]"
								type="number"
								outlined
								small
								dense
								ref="sqft"
								:hide-details="!fieldHasError('sqft')"
							></v-text-field>
						</v-col>
						<v-col class="ma-0 pa-0 mt-1 mb-2" cols="12">
							<div class="field-label">
								Construction Type
								<span class="text-span">*</span>
							</div>

							<v-radio-group
								:class="
									`mt-1 constructionType-${$store.state.data.application.constructionType}`
								"
								v-model="$store.state.data.application.constructionType"
								:rules="[rules.requireField]"
								row
								dense
								ref="constructionType"
								:hide-details="!fieldHasError('constructionType')"
							>
								<v-row no-gutters>
									<v-col
										class="text-center pa-1"
										v-for="(constructionType, index) in constructionTypeList"
										:key="index"
										:cols="colSize(4, 4, 6, 6)"
									>
										<v-btn
											block
											small
											style="border: 2px solid; border-radius: 6px;"
											class="caption"
											:class="`constructionType-${constructionType}`"
											@click="
												$store.state.data.application.constructionType = constructionType
											"
											:color="
												$store.state.data.application.constructionType ==
												constructionType
													? 'primary'
													: '#4a4a4a'
											"
											:outlined="
												$store.state.data.application.constructionType !=
													constructionType
											"
											>{{ index }}</v-btn
										>
									</v-col>
								</v-row>
							</v-radio-group>
						</v-col>
						<v-col class="ma-0 pa-0 mt-1 mb-2" cols="12">
							<div class="field-label">
								How many stories is your home?
								<span class="text-span">*</span>
							</div>
							<div
								v-if="$store.state.data.application.occupancy == 'condo'"
								class="text-block-5"
							>
								The amount of stories in your unit, not the entire complex or
								building you’re in.
							</div>
							<v-radio-group
								:class="
									`mt-1 numberOfStories-${$store.state.data.application.numberOfStories}`
								"
								v-model="$store.state.data.application.numberOfStories"
								:rules="[rules.requireField]"
								row
								dense
								ref="numberOfStories"
								:hide-details="!fieldHasError('numberOfStories')"
							>
								<v-row no-gutters>
									<v-col
										class="text-center pa-1"
										v-for="(numberOfStories, index) in numberOfStoriesList"
										:key="index"
										:cols="colSize(3, 3, 6, 6)"
									>
										<v-btn
											block
											small
											style="border: 2px solid; border-radius: 6px;"
											class="caption"
											:class="`numberOfStories-${numberOfStories}`"
											@click="
												$store.state.data.application.numberOfStories = numberOfStories
											"
											:color="
												$store.state.data.application.numberOfStories ==
												numberOfStories
													? 'primary'
													: '#4a4a4a'
											"
											:outlined="
												$store.state.data.application.numberOfStories !=
													numberOfStories
											"
											>{{ index }}</v-btn
										>
									</v-col>
								</v-row>
							</v-radio-group>
						</v-col>
						<v-col class="ma-0 pa-0 mb-1 mt-4">
							<!-- @click="currentPageNumber = 4.1" -->
							<v-btn
								large
								block
								@click="submitStructuralInformationFormSubmit()"
								color="primary"
								>CONTINUE</v-btn
							>
						</v-col>
					</v-row>
				</v-form>
			</div>
		</div>

		<!-- Page 4.1-->
		<div
			class="my-3 mt-5 px-6"
			:class="['sm', 'xs'].includes($vuetify.breakpoint.name) ? 'mb-12' : ''"
			v-if="currentPageNumber == 4.1"
		>
			<div class="section-3 mb-0 mt-1 pb-0">
				<h1 class="heading-8 my-0">Home Usage</h1>
				<div class="text-block-1 my-0 mt-2">
					Your primary home is a home you live in the majority of the year.
				</div>
			</div>
			<div
				style="width: 100%; max-width: 600px; left: 0; right: 0; margin: 0 auto"
				class="px-6"
			>
				<v-form v-model="isFormValid" ref="form">
					<v-row>
						<v-col class="ma-0 pa-0 mt-6 mb-2" cols="12">
							<v-radio-group
								:class="
									`mt-1 propertyType-${$store.state.data.application.propertyType}`
								"
								v-model="$store.state.data.application.propertyType"
								:rules="[rules.requireField]"
								row
								dense
								ref="propertyType"
								:hide-details="!fieldHasError('propertyType')"
							>
								<v-row no-gutters>
									<v-col
										class="text-center pa-1"
										v-for="(propertyType, index) in propertyTypeList"
										:key="index"
										:cols="colSize(6, 6, 12, 12)"
									>
										<v-btn
											block
											style="border: 2px solid; border-radius: 6px;"
											class="caption"
											:class="`propertyType-${propertyType}`"
											@click="
												$store.state.data.application.propertyType = propertyType
											"
											:color="
												$store.state.data.application.propertyType ==
												propertyType
													? 'primary'
													: '#4a4a4a'
											"
											:outlined="
												$store.state.data.application.propertyType !=
													propertyType
											"
											>{{ index }}</v-btn
										>
									</v-col>
								</v-row>
							</v-radio-group>
						</v-col>

						<v-col class="ma-0 pa-0 mb-1 mt-4" cols="12">
							<!-- @click="currentPageNumber = 4.1" -->
							<v-btn
								large
								block
								@click="submitHomeUsageFormSubmit()"
								color="primary"
								>CONTINUE</v-btn
							>
						</v-col>
					</v-row>
				</v-form>
			</div>
		</div>

		<!-- Page 5 -->
		<div
			class="my-6 mt-5 px-6"
			:class="['sm', 'xs'].includes($vuetify.breakpoint.name) ? 'mb-12' : ''"
			v-if="currentPageNumber == 5"
		>
			<div class="section-3 pa-0 my-1">
				<h1 class="heading-8 pa-0 ma-0">Pick Your Coverage</h1>
			</div>
			<div class="text-block-3">
				We&#x27;ve recommended these options, you can modify below.
			</div>
			<div
				style="width: 100%; max-width: 600px; left: 0; right: 0; margin: 0 auto"
				class="px-6 mt-4"
			>
				<form>
					<v-row class="">
						<v-col class="ma-0 pa-0 mt-1 mb-2" cols="12" md="12">
							<div class="field-label">
								Building Coverage :
							</div>
							<v-text-field
								prefix="$"
								:min="0"
								type="text"
								:class="`buildingCoverage-${buildingCoverage}`"
								v-model="buildingCoverage"
								ref="buildingCoverage"
								:hide-details="!fieldHasError('buildingCoverage')"
								:rules="[rules.requireField]"
								outlined
								hide-actions
								hide-headers
								dense
								block
							></v-text-field>
						</v-col>
						<v-col class="ma-0 pa-0 mt-1 mb-2" cols="12" md="12">
							<div class="field-label">
								Personal Property :
							</div>
							<v-text-field
								prefix="$"
								:min="0"
								type="text"
								:class="`contentCoverage-${contentCoverage}`"
								v-model="contentCoverage"
								ref="contentCoverage"
								:hide-details="!fieldHasError('contentCoverage')"
								:rules="[rules.requireField]"
								outlined
								hide-actions
								hide-headers
								dense
								block
							></v-text-field>
						</v-col>
					</v-row>
					<br />
					<label id="txtpersonal" class="field-label-5 mt-0 pt-0">
						Deductible:
						<span
							>${{
								formatThisAsCurrency($store.state.data.application.deductible)
							}}</span
						>
					</label>
					<v-row>
						<v-col
							class="text-center"
							v-for="(detachableOption, index) in detachableOptions"
							:key="index"
							:cols="colSize(3, 3, 6, 6)"
						>
							<v-btn
								small
								block
								style="border: 2px solid; border-radius: 6px;"
								class="caption"
								:class="`deductible-${detachableOption}`"
								@click="
									$store.state.data.application.deductible = detachableOption
								"
								:color="
									$store.state.data.application.deductible == detachableOption
										? 'primary'
										: '#4a4a4a'
								"
								:outlined="
									$store.state.data.application.deductible != detachableOption
								"
								>${{ formatThisAsCurrency(detachableOption) }}</v-btn
							>
						</v-col>
					</v-row>
				</form>
				<v-btn
					large
					block
					@click="currentPageNumber = 7"
					class="mt-3"
					color="primary"
					>CONTINUE</v-btn
				>
			</div>

			<v-dialog
				v-model="invalidBuildingCoverageDialog"
				max-width="800px"
				persistent
			>
				<v-card>
					<v-card-title class="grey lighten-2 px-2">
						Building Coverage Alert
						<v-spacer></v-spacer>

						<v-btn
							color="error"
							icon
							@click="invalidBuildingCoverageDialog = false"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-card-title>

					<v-card-text
						v-if="invalidBuildingCoverageDialog"
						class="text-center pa-4 ma-0"
					>
						<h5 class="my-6" v-html="invalidBuildingCoverageDialogMessage"></h5>
					</v-card-text>

					<v-card-actions class="grey lighten-2 px-2">
						<v-spacer></v-spacer>
						<v-btn
							color="primary"
							text
							@click="invalidBuildingCoverageDialog = false"
							>Okay</v-btn
						>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>

		<!-- Page 6 -->
		<div
			class="py-3 mt-2 px-6"
			:class="['sm', 'xs'].includes($vuetify.breakpoint.name) ? 'mb-12' : ''"
			v-if="currentPageNumber == 6"
		>
			<div class="section-3 py-0 my-0">
				<h1
					class="heading-8 py-0 my-0"
					:style="
						['sm', 'xs'].includes($vuetify.breakpoint.name)
							? 'font-size:0.7rem;'
							: ''
					"
				>
					We offer these upgrades, take your pick:
				</h1>
			</div>
			<div
				class="text-block-3 py-0 my-0"
				:style="
					['sm', 'xs'].includes($vuetify.breakpoint.name)
						? 'font-size:0.7rem;'
						: ''
				"
			>
				Please click on the yes / no switch to turn an option on or off.
			</div>
			<!-- <div
          class="text-block-5-copy py-0 my-0"
          :style="
            ['sm', 'xs'].includes($vuetify.breakpoint.name)
              ? 'display:none;'
              : ''
          "
        >
          If there are additional coverage options click and drag on the pink
          dot to update your coverage.
        </div> -->
			<div
				class="my-3"
				style="width: 100%; max-width: 700px; left: 0; right: 0; margin: 0 auto"
			>
				<v-form v-model="isFormValid" ref="form">
					<v-list class="transparent">
						<v-list-item
							class="my-4 rounded elevation-0 upgrade-options"
							v-for="(upgradeOption, index) in upgradeOptionList"
							:key="index"
						>
							<v-list-item-action class="pa-0 ma-0">
								<v-img
									contain
									:height="
										['sm', 'xs'].includes($vuetify.breakpoint.name)
											? '70px'
											: '90px'
									"
									:width="
										['sm', 'xs'].includes($vuetify.breakpoint.name)
											? '70px'
											: '90px'
									"
									:src="upgradeOption.image"
								></v-img>
							</v-list-item-action>
							<v-list-item-content class="ml-3">
								<p class="text-left subtitle font-weight-bold text--lighten-1">
									{{ upgradeOption.disc }}
									<v-btn
										@click="
											(upgradeOptionExplanation = upgradeOption.explain),
												(upgradeOptionExplanationDialog = true)
										"
										color="primary"
										icon
									>
										<v-icon>mdi-information</v-icon>
									</v-btn>
								</p>
							</v-list-item-content>
							<div v-if="!upgradeOption.isInput" class="v-list-item__action">
								<v-switch
									:class="
										`${upgradeOption.data}-${
											$store.state.data.application[upgradeOption.data]
										}`
									"
									v-model="$store.state.data.application[upgradeOption.data]"
								>
									<template v-slot:label>
										<div class="mt-1 mx-2">
											{{
												$store.state.data.application[upgradeOption.data]
													? "Yes"
													: "No"
											}}
										</div>
									</template>
								</v-switch>
							</div>
							<div v-else class="v-list-item__action">
								<v-switch
									:class="
										`${upgradeOption.data}-${
											$store.state.data.application[upgradeOption.data]
										}`
									"
									v-model="upgradeOption.isActive"
									@click.stop="upgradeOptionDialogShowHide(index)"
								>
									<template v-slot:label>
										<div class="mt-1 mx-2">
											{{ upgradeOption.isActive ? "Yes" : "No" }}
										</div>
									</template>
								</v-switch>

								<v-dialog
									v-model="upgradeOption.dialog"
									max-width="600px"
									persistent
								>
									<v-card>
										<v-card-title>{{ upgradeOption.disc }}</v-card-title>
										<v-card-title>
											<v-text-field
												style="float: left"
												prefix="$"
												min="0"
												type="number"
												:class="
													`${upgradeOption.disc}-${
														$store.state.data.application[upgradeOption.data]
													}`
												"
												v-model="
													$store.state.data.application[upgradeOption.data]
												"
												outlined
												hide-details
												hide-actions
												hide-headers
												dense
											></v-text-field>
										</v-card-title>
										<v-card-text>
											<v-slider
												:class="
													`${upgradeOption.data}-${
														$store.state.data.application[upgradeOption.data]
													}`
												"
												v-model="
													$store.state.data.application[upgradeOption.data]
												"
												:min="upgradeOption.min"
												:max="upgradeOption.max"
												prepend-icon="$"
											>
												<template v-slot:prepend>
													<div>${{ upgradeOption.min }}</div>
												</template>
												<template v-slot:append>
													<div>${{ upgradeOption.max }}</div>
												</template>
											</v-slider>
										</v-card-text>
										<v-card-actions>
											<v-btn
												text
												:class="
													`${upgradeOption.data}-${
														$store.state.data.application[upgradeOption.data]
													}`
												"
												@click="
													(upgradeOption.dialog = false),
														(upgradeOption.isActive = false),
														($store.state.data.application[
															upgradeOption.data
														] = 0)
												"
												>Cancel</v-btn
											>
											<v-spacer></v-spacer>
											<v-btn
												color="primary"
												text
												@click="upgradeOption.dialog = false"
												>Done</v-btn
											>
										</v-card-actions>
									</v-card>
								</v-dialog>
							</div>
						</v-list-item>
					</v-list>
					<v-btn large block @click="currentPageNumber = 7" color="primary"
						>CONTINUE</v-btn
					>
				</v-form>
			</div>

			<v-dialog
				v-if="upgradeOptionExplanationDialog"
				v-model="upgradeOptionExplanationDialog"
				max-width="700px"
				persistent
			>
				<v-card>
					<v-card-title class="grey lighten-3">
						<v-spacer></v-spacer> Information <v-spacer></v-spacer
					></v-card-title>
					<v-card-text class="text-center pa-12 ma-0">
						<h6 class="pa-3">
							{{ upgradeOptionExplanation }}
						</h6>
					</v-card-text>

					<v-card-actions class="grey lighten-2 px-2">
						<v-btn
							color="primary"
							block
							text
							@click="upgradeOptionExplanationDialog = false"
							>Close</v-btn
						>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>

		<!-- Page 7 -->
		<div
			class="my-3 mt-5 px-6"
			:class="['sm', 'xs'].includes($vuetify.breakpoint.name) ? 'mb-12' : ''"
			v-if="currentPageNumber == 7"
		>
			<div class="section-3 pa-0 mt-12">
				<h1
					:style="
						['sm', 'xs'].includes($vuetify.breakpoint.name)
							? 'font-size:1rem;'
							: ''
					"
					class="text-center my-0 pa-0 mb-2"
				>
					<v-icon size="60">mdi-cellphone-key</v-icon>
				</h1>
			</div>
			<div class="section-3 pa-0">
				<h1
					:style="
						['sm', 'xs'].includes($vuetify.breakpoint.name)
							? 'font-size:0.8rem;'
							: ''
					"
					class="heading-8 my-0 pa-0 mb-2"
				>
					Lets Confirm your phone number
					<!-- We won't spam you with unwanted calls -->
				</h1>
			</div>
			<div
				:style="
					['sm', 'xs'].includes($vuetify.breakpoint.name)
						? 'font-size:0.7rem;'
						: ''
				"
				class="text-center caption my-0"
			>
				We use this to confirm your identity and provide custom quotes.
			</div>
			<div class="section-4-copy my-1">
				<v-form
					v-model="isFormValid"
					ref="form"
					style="width: 100%; max-width: 600px"
				>
					<v-row class="pa-0 ma-0">
						<v-col cols="12" md="8" class="pa-2 ma-0">
							<div class="field-label">
								Phone Number
								<span class="text-span">*</span>
							</div>
							<v-text-field
								class="phone"
								:prefix="$store.state.data.application.country_code"
								v-model="$store.state.data.application.phone"
								:rules="[
									rules.requireField,
									rules.minLen(14, '10 Digits required.')
								]"
								v-mask="'(###) ###-####'"
								outlined
								block
								dense
								ref="phone"
								:disabled="$store.state.data.application.is_otp_sent"
								:hide-details="!fieldHasError('phone')"
							></v-text-field>
						</v-col>
						<v-col cols="12" md="4" class="pa-2 ma-0">
							<v-btn
								class="mt-5"
								large
								block
								v-if="!$store.state.data.application.is_otp_sent"
								@click="sendOtpCode()"
								color="primary"
								>Send Code</v-btn
							>
							<v-btn
								class="mt-5"
								large
								block
								v-else
								@click="changePhoneNumber()"
								color="primary"
								>Change</v-btn
							>
						</v-col>
						<template
							v-if="
								$store.state.data.application.is_otp_sent &&
									$store.state.data.application.is_phone_number_verified ==
										false
							"
						>
							<v-col cols="12" md="8" class="pa-2 ma-0">
								<div class="field-label">
									Rocket Flood Code
									<span class="text-span">*</span>
								</div>
								<v-text-field
									class="otp_code"
									v-model="$store.state.data.application.otp_code"
									:rules="[rules.requireField, rules.minLen(5)]"
									v-mask="'#####'"
									outlined
									block
									dense
									ref="otp_code"
									:hide-details="!fieldHasError('otp_code')"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="4" class="pa-2 ma-0">
								<v-btn
									class="mt-5"
									large
									block
									@click="verifyOtpCode()"
									color="primary"
									>Verify</v-btn
								>
							</v-col>
						</template>
						<v-col cols="12">
							<v-btn
								:disabled="
									!$store.state.data.application.is_phone_number_verified
								"
								large
								block
								@click="currentPageNumber = 7.1"
								color="primary"
								>CONTINUE</v-btn
							>
						</v-col>
					</v-row>
				</v-form>
				<div
					class="my-6 mt-6"
					style="
						width: 90%;
						max-width:600px;
						left: 0;
						right: 0;
						margin: 0 auto;
						position: relative;
					"
				>
					<p class="caption text-center">
						By clicking "Continue" you agree to receive a 5-digit code from
						Rocket Flood to verify this phone number, and receive updates about
						Rocket Flood. Message frequency may vary. Message and data rates may
						apply. Reply HELP for help or STOP to cancel. Rocket Flood
						<a
							href="#"
							@click.prevent.stop="
								$route.name != 'terms-of-use'
									? $router.push({ name: 'terms-of-use' })
									: ''
							"
						>
							Term Of Use
						</a>
						and
						<a
							href="#"
							@click.prevent.stop="
								$route.name != 'privacy-policy'
									? $router.push({ name: 'privacy-policy' })
									: ''
							"
							>Privacy Policy</a
						>.
					</p>
				</div>
			</div>
		</div>

		<!-- Page 7.1 -->
		<div
			class="my-3 mt-5 px-6"
			:class="['sm', 'xs'].includes($vuetify.breakpoint.name) ? 'mb-12' : ''"
			v-if="currentPageNumber == 7.1"
		>
			<div class="section-3 pa-0">
				<h1
					:style="
						['sm', 'xs'].includes($vuetify.breakpoint.name)
							? 'font-size:0.8rem;'
							: ''
					"
					class="heading-8 my-0 pa-0"
				>
					Congratulation, this is your last step!
				</h1>
			</div>
			<div
				:style="
					['sm', 'xs'].includes($vuetify.breakpoint.name)
						? 'font-size:0.7rem;'
						: ''
				"
				class="text-block-3 my-0"
			>
				Enter your contact information in the form below to access your money
				saving quote.
			</div>
			<div class="section-4-copy my-1">
				<v-form
					v-model="isFormValid"
					ref="form"
					style="width: 100%; max-width: 600px"
				>
					<v-row class="pa-0 ma-0">
						<v-col cols="12" md="6" class="pa-2 ma-0">
							<div class="field-label">
								First Name
								<span class="text-span">*</span>
							</div>
							<v-text-field
								class="firstNamepa-0 ma-0"
								v-model="$store.state.data.application.firstName"
								:rules="[rules.requireField]"
								outlined
								dense
								ref="firstName"
								:hide-details="!fieldHasError('firstName')"
							></v-text-field>
						</v-col>
						<v-col cols="12" md="6" class="pa-2 ma-0">
							<div class="field-label">
								Last Name
								<span class="text-span">*</span>
							</div>
							<v-text-field
								class="lastName"
								v-model="$store.state.data.application.lastName"
								:rules="[rules.requireField]"
								outlined
								dense
								ref="lastName"
								:hide-details="!fieldHasError('lastName')"
							></v-text-field>
						</v-col>
						<v-col cols="12" md="6" class="pa-2 ma-0">
							<div class="field-label">
								Email
								<span class="text-span">*</span>
							</div>
							<v-text-field
								class="email"
								v-model="$store.state.data.application.email"
								:rules="[rules.requireField, rules.validEmail]"
								outlined
								dense
								ref="email"
								:hide-details="!fieldHasError('email')"
							></v-text-field>
						</v-col>
						<v-col cols="12" md="6" class="pa-2 ma-0">
							<div class="field-label">
								Phone Number
								<span class="text-span">*</span>
							</div>
							<v-text-field
								class="phone"
								:prefix="$store.state.data.application.country_code"
								v-model="$store.state.data.application.phone"
								:rules="[
									rules.requireField,
									rules.minLen(14, '10 Digits required.')
								]"
								v-mask="'(###) ###-####'"
								disabled
								outlined
								dense
								ref="phone"
								:hide-details="!fieldHasError('phone')"
							></v-text-field>
						</v-col>
						<v-col
							v-if="
								$store.state.data.application.application_submitter_type ==
									'agent'
							"
							cols="12"
							class="pa-2 ma-0"
						>
							<div class="text-block-5">
								Would you like to copy your client on the flood insurance
								quotes?
							</div>

							<v-radio-group
								:class="
									`mt-1 ccToClient-${$store.state.data.application.ccToClient}`
								"
								v-model="$store.state.data.application.ccToClient"
								:rules="[rules.requireField]"
								row
								dense
								ref="ccToClient"
								:hide-details="!fieldHasError('ccToClient')"
							>
								<v-row no-gutters>
									<v-col class="text-center pa-1" :cols="colSize(6, 6, 12, 12)">
										<v-btn
											small
											block
											style="border: 2px solid; border-radius: 6px;"
											class="caption"
											:class="`ccToClient-yes`"
											@click="$store.state.data.application.ccToClient = 'Yes'"
											:color="
												$store.state.data.application.ccToClient == 'Yes'
													? 'primary'
													: '#4a4a4a'
											"
											:outlined="
												$store.state.data.application.ccToClient != 'Yes'
											"
											>Yes</v-btn
										>
									</v-col>
									<v-col class="text-center pa-1" :cols="colSize(6, 6, 12, 12)">
										<v-btn
											small
											block
											style="border: 2px solid; border-radius: 6px;"
											class="caption"
											:class="`ccToClient-no`"
											@click="$store.state.data.application.ccToClient = 'No'"
											:color="
												$store.state.data.application.ccToClient == 'No'
													? 'primary'
													: '#4a4a4a'
											"
											:outlined="
												$store.state.data.application.ccToClient != 'No'
											"
											>No</v-btn
										>
									</v-col>
								</v-row>
							</v-radio-group>
						</v-col>
						<v-col
							v-if="$store.state.data.application.ccToClient == 'Yes'"
							cols="12"
							md="6"
							class="pa-2 ma-0"
						>
							<div class="field-label">
								Client Email
								<span class="text-span">*</span>
							</div>
							<v-text-field
								class="new_email"
								v-model="$store.state.data.application.new_email"
								:rules="[rules.requireField, rules.validEmail]"
								outlined
								dense
								ref="new_email"
								:hide-details="!fieldHasError('new_email')"
							></v-text-field>
						</v-col>
						<v-col
							v-if="$store.state.data.application.ccToClient == 'Yes'"
							cols="12"
							md="6"
							class="pa-2 ma-0"
						>
							<div class="field-label">
								Client Phone Number
								<span class="text-span">*</span>
							</div>
							<v-text-field
								class="new_phone"
								:prefix="$store.state.data.application.country_code"
								v-model="$store.state.data.application.new_phone"
								:rules="[
									rules.requireField,
									rules.minLen(14, '10 Digits required.')
								]"
								v-mask="'(###) ###-####'"
								outlined
								dense
								ref="new_phone"
								:hide-details="!fieldHasError('new_phone')"
							></v-text-field>
						</v-col>
						<v-col>
							<!-- @click="currentPageNumber = 8" -->
							<v-btn
								large
								block
								@click="personalInfoFormSubmit()"
								color="primary"
								>CONTINUE</v-btn
							>
						</v-col>
					</v-row>
				</v-form>
				<div
					class="px-12 ma-12 text-center caption"
					:style="
						['sm', 'xs'].includes($vuetify.breakpoint.name)
							? 'padding:10px !important;margin:0 !important;'
							: ''
					"
					style="color:#aaaaaa;"
				>
					<small>
						By clicking the "continue" button you hereby acknowledge, agree, and
						give express consent to receive marketing/telemarketing or any
						electronic form of solicitation, via automatic telephone dialing
						system, artificial/pre-recorded message, text/sms/SMS message,
						voicemail drop, ring-less services, voicemail messages from all
						third parties, companies, businesses or organizations with a
						contractual obligation to Rocket MGA LLC. and you agree that all
						agreements, notices, disclosures and other communications that we or
						any third party, company, business or organization with a
						contractual obligation to Rocket MGA LLC., provided to you
						electronically, via email and on the Site, satisfy any legal
						requirement that such communications be in writing.
					</small>
				</div>
			</div>
		</div>

		<!-- Page 8 -->
		<div
			v-if="currentPageNumber == 8"
			class="my-3 mt-5 px-6"
			:class="['sm', 'xs'].includes($vuetify.breakpoint.name) ? 'mb-12' : ''"
		>
			<div
				class="my-3 py-0 my-0"
				style="
					width: 80%;
					left: 0;
					right: 0;
					margin: 0 auto;
					position: relative;
					text-align: center;
				"
			>
				<h4
					class="primary-heading--text mt-0"
					:style="
						['sm', 'xs'].includes($vuetify.breakpoint.name)
							? 'font-size:1.3rem;'
							: ''
					"
				>
					Wow that was easy!
					<br />
				</h4>
			</div>
			<div class="section-finished pa-0">
				<!-- style="width:100%; max-width:1000px; left:0; right:0; margin:0 auto; " -->
				<div class="_2-col-flex-block-02 pa-3 mb-0">
					<div class="big-col-l">
						<h2 class="form-heading-01 pa-0 ma-0 primary-heading--text">
							Quote Profile
						</h2>
						<div class="results-form-block-01 w-form">
							<form
								id="email-form"
								name="email-form"
								class="results-01 ma-0 pa-0"
							>
								<div class="form-2-col-wrapper">
									<label for="name" class="results-lable primary-heading--text"
										>Effective Date</label
									>
								</div>
								<input
									type="date"
									class="results-text-block w-input ma-0"
									maxlength="256"
									name="Effective-Date"
									placeholder="mm/dd/yyyy"
									:class="
										`effectiveDate-${$store.state.data.application.effectiveDate}`
									"
									v-model="$store.state.data.application.effectiveDate"
									id="Effective-Date"
								/>
								<div class="form-2-col-wrapper">
									<label for="name" class="results-lable primary-heading--text"
										>Property Address</label
									>
								</div>
								<input
									type="text"
									class="results-text-block w-input ma-0"
									maxlength="256"
									name="Mailing-Address"
									id="Mailing-Address"
									:value="
										$store.state.data.application.addr1 +
											', ' +
											$store.state.data.application.city +
											', ' +
											$store.state.data.application.state +
											', ' +
											$store.state.data.application.zip
									"
								/>

								<div class="form-2-col-wrapper">
									<label for="name" class="results-lable primary-heading--text"
										>Coverage</label
									>
									<!-- <a
										@click="currentPageNumber = 5"
										class="form-edit-cta w-inline-block"
									>
										<div class="edit-cta-text">Edit</div>
									</a> -->
								</div>
								<div class="main-results-wrapper">
									<div
										class="form-2-col-wrapper-02"
										style="display: inline-block"
									>
										<div
											class="results-label-02"
											style="float: left; width: 60%; height: 30px"
										>
											Building
										</div>
										<div
											style="
												float: left;
												width: 10%;
												height: 30px;
												text-align: right;
												padding-right: 4px;
											"
										>
											$
										</div>
										<div style="float: left; width: 30%; height: 30px">
											<input
												style="
													width: 100% !important;
													min-width: 100% !important;
													text-align: left;
													height: 25px;
												"
												type="text"
												class="numbers w-input pa-0 ma-0"
												maxlength="256"
												name="Building-Number"
												id="Building-Number"
												:class="`buildingCoverage-${buildingCoverage}`"
												v-model="buildingCoverage"
											/>
										</div>
										<!-- placeholder="$250,000" -->
									</div>
									<div
										class="form-2-col-wrapper-02"
										style="display: inline-block"
									>
										<div
											class="results-label-02"
											style="float: left; width: 60%; height: 30px"
										>
											Contents
										</div>
										<div
											style="
												float: left;
												width: 10%;
												height: 30px;
												text-align: right;
												padding-right: 4px;
											"
										>
											$
										</div>
										<div style="float: left; width: 30%; height: 30px">
											<input
												style="
													width: 100% !important;
													min-width: 100% !important;
													text-align: left;
													height: 25px;
												"
												type="text"
												class="numbers w-input pa-0 ma-0"
												maxlength="256"
												name="Contents-Number"
												id="Contents-Number"
												:class="`contentCoverage-${contentCoverage}`"
												v-model="contentCoverage"
											/>
										</div>
										<!-- placeholder="$250,000" -->
									</div>
									<h3
										class="form-heading-02 pa-0 ma-0 my-1 primary-heading--text"
									>
										Optional Coverage
									</h3>
									<div class="form-2-col-wrapper-02">
										<label
											for="Replacement-Cost-Number"
											class="results-label-02"
											>Replacement Cost on Contents</label
										>
										<div class="mt-1 mx-2">
											{{
												$store.state.data.application.hasOptionalReplacementCost
													? "Yes"
													: "No"
											}}
										</div>
									</div>
									<div class="form-2-col-wrapper-02">
										<label for="Temp-Living-Number" class="results-label-02"
											>Temporary Living Expense</label
										>
										<div class="mt-1 mx-2">
											{{
												$store.state.data.application
													.hasOptionalTemporaryLivingExpenses
													? "Yes"
													: "No"
											}}
										</div>
									</div>
									<div class="form-2-col-wrapper-02">
										<label
											for="Detached-Structure-Number"
											class="results-label-02"
											>Detached Structures</label
										>
										<div class="mt-1 mx-2">
											{{
												$store.state.data.application
													.unattachedStructureCoverage
													? "Yes"
													: "No"
											}}
										</div>
									</div>
									<div class="form-2-col-wrapper-02">
										<label for="Pool-Repair-Number" class="results-label-02"
											>Pool Repair and Refill</label
										>
										<div class="mt-1 mx-2">
											{{
												$store.state.data.application.poolCoverage
													? "Yes"
													: "No"
											}}
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div class="big-col-r">
						<div>
							<h2 class="form-heading-01 pa-0 ma-0 primary-heading--text">
								Your quotes are running!
							</h2>
						</div>
						<div
							v-if="!['sm', 'xs'].includes($vuetify.breakpoint.name)"
							style="min-height: 20px"
						></div>
						<ul>
							<li class="mt-4">
								Rocket Flood uses powerful technology to quote over 20 Flood
								insurance providers in minutes.
							</li>
							<li class="mt-4">
								When our quoting process is complete you will automatically
								receive the most competitive quote via email and MMS text
								message.
							</li>
							<li class="mt-4">
								One of our agents will be in touch shortly to review the
								coverage and apply all possible discounts to the quote.
							</li>
						</ul>
						<div style="width: 100%; text-align: center">
							For immediate assistance please contact Rocket Flood at
							<a :href="`tel:${companyPhoneNumber}`">{{
								companyPhoneNumber
							}}</a>
							or
							<a
								href="mailto:Quotes@RocketFlood.com?subject=Quote%20Page%20Question"
								>Quotes@RocketFlood.com</a
							>
						</div>
					</div>
				</div>
				<div
					class="my-6"
					style="
						width: 80%;
						left: 0;
						right: 0;
						margin: 0 auto;
						position: relative;
					"
				>
					<p class="subtitle-1 text-center">
						Pricing &amp; eligibility based on the information provided.
						&nbsp;All quotes require final verification and issuance by a
						licensed Rocket Flood agent. &nbsp;To expedite the verification
						process or finalize a quote please click here (email
						<a
							href="mailto:Quotes@RocketFlood.com?subject=Quote%20Page%20Question"
							>Quotes@RocketFlood.com</a
						>
						with customer reference number) or call us at
						<a :href="`tel:${companyPhoneNumber}`">{{ companyPhoneNumber }}</a
						>.
					</p>
				</div>
			</div>

			<v-dialog v-model="enrolledDialog" max-width="700px" persistent>
				<v-card>
					<v-card-title class="primary white--text px-3">
						Thank you for Enrolling in Rocked Flood
						<v-spacer></v-spacer>

						<v-btn color="error" icon @click="enrolledDialog = false">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-card-title>

					<v-card-text class="text-center pa-0 ma-0">
						<h6 class="pa-3">
							One of our agents will be in touch with you shortly to review the
							coverage and finalize the policy.
							<br />For immediate assistance please contact the rocket flood
							team at {{ companyPhoneNumber }} or quotes@rocketflood.com
						</h6>
					</v-card-text>

					<v-card-actions class="grey lighten-2 px-2">
						<v-btn color="primary" block text @click="enrolledDialog = false"
							>Okay</v-btn
						>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
	import goTo from "vuetify/lib/services/goto";
	import { mapState } from "vuex";
	import SelectionBox from "@/components/SelectionBox";
	import states from "@/plugins/states.json";

	const formatAsCurrency = value => {
		let newVal = 0;
		if (value) {
			// console.log(Number(value.toString().replace(/[^0-9\.]/g, "")));
			newVal = Number.parseInt(value.toString().replace(/[^0-9\.]/g, ""))
				.toString()
				.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
		}
		return newVal;
	};

	export default {
		components: {
			SelectionBox
		},
		data: () => {
			return {
				otp_timeout_id: null,
				enrolledDialog: false,
				minBuildingCoverageValidValues: [],
				invalidBuildingCoverageDialog: false,
				invalidBuildingCoverageDialogMessage: "",
				ccToClient: false,
				yearMenu: false,
				isFormValid: false,
				states: states,
				detachableOptions: [1000, 2000, 5000, 10000],
				upgradeOptionExplanationDialog: false,
				upgradeOptionExplanation: null,
				rules: {
					requireField: v => !!v || "Field is required",
					validEmail: v => /.+@.+\..+/.test(v) || "E-mail must be valid",
					validYear: v => v.length == 4 || "Must be a valid Year",
					minLen: (l, msg = null) => v =>
						v == null ||
						(v && v.length >= l) ||
						(msg ? msg : `min. ${l} Characters.`)
				},
				upgradeOptionList: [
					{
						image: "/images/replacement-cost.png",
						disc: "Replacement Cost - Personal Property",
						explain:
							"In the event of a covered loss, your policy covers your personal property (up to the amount you selected for personal property coverage) with an adjustment for actual cash value (depreciation). If you would like your claim settled for the replacement cost of the items at the time of the loss, without depreciation, select this extra protection.",
						data: "hasOptionalReplacementCost",
						isInput: false
					},
					{
						image: "/images/temp-living.png",
						disc: "Temporary Living Expense",
						explain:
							"With this coverage, you will be reimbursed 75% of your covered temporary living expense, with a maximum reimbursement of $7500.",
						data: "hasOptionalTemporaryLivingExpenses",
						isInput: false
					},
					{
						image: "/images/detached-structure.png",
						disc: "Detached Structure",
						explain:
							"Do you have a detached garage, shed or in-law apartment? Select this coverage and the value needed to provide extra protection for buildings other than your main residence.",
						data: "unattachedStructureCoverage",
						isActive: false,
						isInput: true,
						dialog: false,
						min: 0,
						max: 50000
					}
				],
				constructionTypeList: {
					"Wood Frame": "wood",
					"Steel Frame": "steel",
					Concrete: "concrete",
					"Masonry / Bricks": "masonry"
				},
				numberOfStoriesList: {
					1: 1,
					2: 2,
					"3 or more": 3
				},
				propertyTypeList: {
					"Primary Home": "primaryHome",
					"Secondary or Seasonal": "secondaryHome",
					"Rental - I’m the owner/landlord": "rentalOwner",
					"Rental - I’m the tenant": "rentalTenant"
				}
			};
		},
		head: {
			title: function() {
				return {
					inner: "Address Details"
				};
			},
			meta: [{ name: "description", content: "RocketFlood", id: "desc" }]
		},
		computed: {
			...mapState(["data", "responses"]),
			buildingCoverage: {
				set(value) {
					this.$store.state.data.application.buildingCoverage = value;
					return value;
				},
				get() {
					return formatAsCurrency(
						this.$store.state.data.application.buildingCoverage
					);
				}
			},
			contentCoverage: {
				set(value) {
					this.$store.state.data.application.contentCoverage = value;
					return value;
				},
				get() {
					return formatAsCurrency(
						this.$store.state.data.application.contentCoverage
					);
				}
			}
		},
		watch: {
			"$store.state.data.application.sqft"(val) {
				this.applyBuildingCoverageLogic();
			},
			currentPageNumber(val, oldVal) {
				if (val != oldVal) {
					goTo(0);
				}

				if (val == 4) {
					this.applyBuildingCoverageLogic();
				}

				if (
					val > 5 &&
					!this.minBuildingCoverageValidValues.includes(
						this.getNumberFromStr(
							this.$store.state.data?.application?.buildingCoverage
						)
					) &&
					this.getNumberFromStr(
						this.$store.state.data?.application?.buildingCoverage
					) < this.minBuildingCoverageValidValues[0]
				) {
					this.invalidBuildingCoverageDialog = true;
					this.pageBack();
				}

				// if (val == 6) {
				//   this.upgradeOptionDialogShowHide(2);
				// }

				if (val == 7) {
					this.$store.state.enrollBtnIsDisabled = false;
					this.$store.state.emailBtnIsDisabled = false;
				}
			}
		},
		mounted() {
			// this.getAuthenticationToken();
			this.$store.state.data.application.effectiveDate = this.date(new Date());
		},
		methods: {
			formatAsCurrency,
			goToAddressDetailsFormOrNext() {
				if (
					this.$store.state.data.application.do_not_sell_my_info == true ||
					this.$store.state.data.application.home_detail_received_from_api ==
						false
				) {
					this.currentPageNumber = 1;
				} else {
					this.currentPageNumber = 1;
					this.currentPageNumber = 2;
				}
			},
			changePhoneNumber() {
				if (this.otp_timeout_id) clearTimeout(this.otp_timeout_id);
				this.otp_timeout_id = null;
				this.$store.state.data.application.is_otp_sent = false;
				this.$store.state.data.application.is_phone_number_verified = false;
				this.$store.state.data.application.phone = null;
				this.currentPageNumber = 7;
			},
			sendOtpCode() {
				var $this = this;
				this.$refs.form.validate();
				if (!this.isFormValid) return;

				let data = {
					country_code: this.$store.state.data.application.country_code,
					phone_number: this.$store.state.data.application.phone
				};

				if (data.phone_number == "(335) 630-4958") {
					this.$store.state.data.application.is_otp_sent = true;
					this.otp_timeout_id = setTimeout(() => {
						this.$store.state.data.application.is_otp_sent = false;
						this.otp_timeout_id = null;
					}, 1000 * 60 * 2);
					return true;
				}

				this.post(
					`${this.agentPortalApiEndpoint}/services/otp/send`,
					data,
					true
				).then(res => {
					if (res.code == 200) {
						this.$store.state.data.application.is_otp_sent = true;
						this.$notify({
							group: "msg",
							title: "Message",
							text: "Opt Message Sent to your Phone.",
							type: "success"
						});
						this.otp_timeout_id = setTimeout(() => {
							this.$store.state.data.application.is_otp_sent = false;
							this.otp_timeout_id = null;
						}, 1000 * 60 * 2);
					} else {
						this.$notify({
							group: "msg",
							title: res.error_obj ? res.message : "Message",
							text: res.error_obj
								? Object.values(res.error_obj)[0][0]
								: res.message,
							type: res.status
						});
					}
				});
			},
			verifyOtpCode() {
				var $this = this;
				this.$refs.form.validate();
				if (!this.isFormValid) return;
				let data = {
					country_code: this.$store.state.data.application.country_code,
					phone_number: this.$store.state.data.application.phone,
					otp_code: this.$store.state.data.application.otp_code
				};

				if (data.phone_number == "(335) 630-4958" && data.otp_code == "49580") {
					clearTimeout(this.otp_timeout_id);
					this.$store.state.data.application.is_otp_sent = true;
					this.$store.state.data.application.is_phone_number_verified = true;
					this.currentPageNumber = 7.1;
					return true;
				}

				this.post(
					`${this.agentPortalApiEndpoint}/services/otp/verify`,
					data,
					true
				).then(res => {
					if (res.code == 200) {
						clearTimeout(this.otp_timeout_id);
						this.$store.state.data.application.is_otp_sent = true;
						this.$store.state.data.application.is_phone_number_verified = true;
						this.currentPageNumber = 7.1;
						this.$notify({
							group: "msg",
							title: "Message",
							text: "Phone number verified successfully.",
							type: "success"
						});
					} else {
						this.$notify({
							group: "msg",
							title: res.error_obj ? res.message : "Message",
							text: res.error_obj
								? Object.values(res.error_obj)[0][0]
								: res.message,
							type: res.status
						});
					}
				});
			},
			formatThisAsCurrency(value) {
				return formatAsCurrency(value);
			},
			applyBuildingCoverageLogic() {
				let sqftValue = this.$store.state.data.application.sqft;

				let currentBuildingCoverageValue = eval(150 * sqftValue);

				if (currentBuildingCoverageValue > 250000) {
					this.minBuildingCoverageValidValues = [eval(150 * sqftValue), 250000];
					this.$store.state.data.application.buildingCoverage = 250000;
					this.invalidBuildingCoverageDialogMessage = `Coverage for online quoting must be $250,000 exactly or a minimum of $150/square ft. Please quote with a Building Coverage of either $250,000 or $${this.formatAsCurrency(
						currentBuildingCoverageValue
					)}+ <br/><br> Your dedicated Rocket Flood Agent can make adjustments for you once the online quotes are completed.`;
				} else if (currentBuildingCoverageValue < 250000) {
					this.minBuildingCoverageValidValues = [eval(150 * sqftValue)];
					this.$store.state.data.application.buildingCoverage = eval(
						150 * sqftValue
					);
					this.invalidBuildingCoverageDialogMessage = `Coverage for online quoting must be a minimum of $150/square ft. Please quote with a Building Coverage of $${this.formatAsCurrency(
						currentBuildingCoverageValue
					)}+ <br/><br> Your dedicated Rocket Flood Agent can make adjustments for you once the online quotes are completed.`;
				}

				this.$store.state.data.application.suggestedBuildingCoverage = this.minBuildingCoverageValidValues;
			},
			fieldHasError(ref) {
				if (this.$refs[ref]) return this.$refs[ref].hasMessages;
				return false;
			},
			upgradeOptionDialogShowHide(index) {
				if (this.upgradeOptionList[index].isActive) {
					this.upgradeOptionList[index].isActive = false;
					this.upgradeOptionList[index].dialog = false;
					this.$store.state.data.application[
						this.upgradeOptionList[index].data
					] = 0;
				} else {
					this.upgradeOptionList[index].isActive = true;
					this.upgradeOptionList[index].dialog = true;
				}
			},
			locationInfoFormSubmit() {
				this.$refs.form.validate();
				if (!this.isFormValid) return;
				this.currentPageNumber = 2;
				// this.createQuote();
			},
			submitStructuralInformationFormSubmit() {
				this.$refs.form.validate();
				if (!this.isFormValid) return;
				this.currentPageNumber = 4.1;
				// this.updateQuote();
			},
			submitHomeUsageFormSubmit() {
				this.$refs.form.validate();
				if (!this.isFormValid) return;
				this.currentPageNumber = 5;
				// this.updateQuote();
			},
			submitContoFloorForm() {
				if (this.$store.state.data.application.condoFloor == 1)
					this.currentPageNumber = 3;
				else this.currentPageNumber = 3;

				// this.currentPageNumber = 4;
			},
			personalInfoFormSubmit() {
				var $this = this;
				this.$refs.form.validate();
				if (!this.isFormValid) return;
				$this.$store.state.specialLoading = true;
				this.getApplicationQuote().then(res => {
					$this.$store.state.specialLoading = false;
					if (res.code == 200) {
						$this.currentPageNumber = 8;

						this.$notify({
							group: "msg",
							title: "Message",
							text: "Application Launched Successfully.",
							type: "success"
						});

						setTimeout(function() {
							$this.$router.push({ name: "thankyou" });
						}, 2000);
					} else {
						this.$notify({
							group: "msg",
							title: res.error_obj ? res.message : "Message",
							text: res.error_obj
								? Object.values(res.error_obj)[0][0]
								: res.message,
							type: res.status
						});
					}
				});
			},
			enrollMeNow() {
				this.enrolledDialog = true;
				this.enrollNow();
			},
			sendEmail() {
				this.sendQuoteEmail();
			},
			downloadPdf() {
				let $this = this;
				this.downloadQuotePdf().then(res => {
					$this.downloadFromUrl($this.baseDownloadUrl + "/" + res);
				});
			},
			downloadFromUrl(downloadUrl) {
				var link = document.createElement("a");
				link.href = downloadUrl;
				link.style = "visibility:hidden";
				link.target = "_blank";
				// link.type = "application/vnd.ms-excel";
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	};
</script>

<style lang="scss">
	@media (max-width: 767px) {
		body {
			font-size: 0.8rem !important;
		}
	}
	.primary-heading--text {
		color: #4a4a4a !important;
		// font-family: Montserrat, sans-serif !important;
		// font-family: Lato, sans-serif !important;
		// --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
		//   Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
		//   "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
		// --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
		//   "Liberation Mono", "Courier New", monospace;
	}

	.v-application .error--text {
		color: #ff5252 !important;
		caret-color: #ff5252 !important;
		// background: antiquewhite;
	}

	.enroll-btn {
		font-size: 18px !important;
		color: white !important;
		background-color: #07db00 !important;
		border: 2px solid #07db00 !important;
		text-align: center;
		font-size: 0.8em;
		letter-spacing: 1px;
		text-transform: capitalize;
		border-radius: 4px;
		max-height: 45px !important;
	}
	.enroll-btn:hover {
		background-color: #7197d4 !important;
		border: 2px solid #7197d4 !important;
	}

	.email-btn {
		font-size: 18px !important;
		color: #4a4a4a !important;
		background-color: transparent;
		border: 2px solid rgba(74, 74, 74, 0.71);
		text-transform: capitalize;
		text-align: center;
		font-size: 0.8em;
		letter-spacing: 1px;
		border-radius: 4px;
		max-height: 45px !important;
	}

	.quote-number {
		width: 100%;
		text-align: center;
		.sign {
			position: relative;
			top: -2em !important;
			font-size: 1.5em;
		}
		.number {
			font-size: 6em;
		}
		.month {
			font-size: 1em;
		}
	}

	.upgrade-options {
		background-color: white;
		border: 0.8px solid rgba(0, 0, 0, 0.3) !important;
	}
	@media (max-width: 767px) {
		.upgrade-options {
			h5 {
				font-size: 12px;
			}
		}
	}

	@keyframes bounce {
		from {
			transform: scale3d(1, 1, 1);
		}
		30% {
			transform: scale3d(0.8, 0.8, 0.8);
			transform-style: preserve-3d;
			will-change: transform;
		}
		60% {
			transform: scale3d(1.1, 1.1, 1.1);
			transform-style: preserve-3d;
			will-change: transform;
		}
		80% {
			transform: scale3d(0.9, 0.9, 0.9);
			transform-style: preserve-3d;
			will-change: transform;
		}
		to {
			transform: scale3d(1, 1, 1);
		}
	}
</style>
