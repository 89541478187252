import Vue from "vue";
import Vuex from "vuex";
import data from "@/plugins/data.json";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    starting_url: null,
    drawer: null,
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    barImage:
      "https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg",
    authenticated: false,
    user: null,
    session_key: null,
    userRole: null,
    currentMode: null,
    msg: {
      show: false,
      type: "",
      title: "",
      disc: "",
    },
    currentPageNumber: 0,
    currentPageNumberHistory: [0],
    loading: false,
    specialLoading: false,
    data: data,
    responses: null,
    enrollBtnIsDisabled: false,
    emailBtnIsDisabled: false,
  },
  mutations: {
    setCurrentUser(state, user) {
      state.user = user;
    },
    setStartingUrl(state, data) {
      state.starting_url = data;
    },
    setAuthenticated(state, value) {
      state.authenticated = value;
    },
    setSessionKey(state, value) {
      state.session_key = value;
    },
    setUserRole(state, value) {
      state.userRole = value;
    },
    setCurrentMode(state, value) {
      state.currentMode = value;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
  },
  getters: {
    getCurrentUser(state) {
      return state.user;
    },
    getSessionKey(state) {
      return state.session_key;
    },
    getAuthenticated(state) {
      return state.authenticated;
    },
    getUserRole(state) {
      return state.userRole;
    },
    getCurrentMode(state) {
      return state.currentMode;
    },
  },
  actions: {
    login({ commit }, data) {
      var user = helpers.isJson(data.user) ? JSON.parse(data.user) : data.user;

      commit("setCurrentUser", user);
      commit("setSessionKey", data.session_key);
      commit("setUserRole", data.user_role);
      commit("setAuthenticated", true);
      commit("setCurrentMode", data.currentMode);
    },
    logout({ commit }) {
      commit("setCurrentUser", null);
      commit("setSessionKey", null);
      commit("setUserRole", null);
      commit("setCurrentMode", null);
      commit("setAuthenticated", false);
    },
    changeCurrentMode({ commit }, newMode) {
      window.localStorage.setItem("currentMode", newMode);
      commit("setCurrentMode", newMode);
    },
    getFreshUserData({ state, dispatch }) {
      // var $this = this;
      var data = {
        session_key: state.session_key,
        user_id: state.user.id,
      };
      axios.post(helpers.baseUrl() + "/get-user-fresh-data", data).then(
        (response) => {
          if (response.data.code == 200)
            dispatch("updateUser", response.data.data.user);
        },
        (error) => {
          // console.log(error);
          // $this.responseErrorMsg(error);
        }
      );
    },
    updateUser({ commit, state }, userData) {
      var user = helpers.isJson(userData) ? JSON.parse(userData) : userData;

      // if (user.profile_image == null)
      //   user.profile_image = "/profile_images/profile-placeholder.png";

      window.localStorage.setItem("user", JSON.stringify(user));
      commit("setCurrentUser", user);
      if (
        state.user.seller &&
        !state.user.buyer &&
        state.currentMode == "buyer"
      ) {
        commit("setCurrentMode", "seller");
      } else if (
        !state.user.seller &&
        state.user.buyer &&
        state.currentMode == "seller"
      ) {
        commit("setCurrentMode", "buyer");
      }
    },
  },
  modules: {},
});
