<template>
  <router-view />
</template>

<script>
export default {
  head() {
    return {
      // link: [
      //   {
      //     rel: "stylesheet",
      //     href:
      //       "https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/css/bootstrap.css",
      //     type: "text/css",
      //   },
      //   {
      //     rel: "stylesheet",
      //     href:
      //       "https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.9.0/css/bootstrap-datepicker.css",
      //     type: "text/css",
      //   },
      //   {
      //     rel: "stylesheet",
      //     href: "//code.jquery.com/ui/1.12.1/themes/base/jquery-ui.css",
      //     type: "text/css",
      //   },
      //   {
      //     rel: "stylesheet",
      //     href:
      //       "https://cdnjs.cloudflare.com/ajax/libs/ion-rangeslider/2.3.0/css/ion.rangeSlider.min.css",
      //     type: "text/css",
      //   },
      //   {
      //     rel: "stylesheet",
      //     href:
      //       "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css",
      //     type: "text/css",
      //   },
      //   {
      //     rel: "stylesheet",
      //     href:
      //       "https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css",
      //     type: "text/css",
      //   },
      //   {
      //     rel: "stylesheet",
      //     href:
      //       "https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900",
      //     type: "text/css",
      //   },
      //   {
      //     rel: "stylesheet",
      //     href: "/css/normalize.css",
      //     type: "text/css",
      //   },
      //   {
      //     rel: "stylesheet",
      //     href: "/css/webflow.css",
      //     type: "text/css",
      //   },
      //   {
      //     rel: "stylesheet",
      //     href: "/css/rocket-flood-02.webflow.css",
      //     type: "text/css",
      //   },
      //   {
      //     rel: "stylesheet",
      //     href: "/css/featherlight.css",
      //     type: "text/css",
      //   },
      //   {
      //     rel: "stylesheet",
      //     href: "/css/style.css",
      //     type: "text/css",
      //   },
      // ],
      script: [
        {
          type: "text/javascript",
          inner: ` window.__lc = window.__lc || {};
    window.__lc.license = 12141411;
    ; (function (n, t, c) { function i(n) { return e._h ? e._h.apply(null, n) : e._q.push(n) } var e = { _q: [], _h: null, _v: "2.0", on: function () { i(["on", c.call(arguments)]) }, once: function () { i(["once", c.call(arguments)]) }, off: function () { i(["off", c.call(arguments)]) }, get: function () { if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load."); return i(["get", c.call(arguments)]) }, call: function () { i(["call", c.call(arguments)]) }, init: function () { var n = t.createElement("script"); n.async = !0, n.type = "text/javascript", n.src = "https://cdn.livechatinc.com/tracking.js", t.head.appendChild(n) } }; !n.__lc.asyncInit && e.init(), n.LiveChatWidget = n.LiveChatWidget || e }(window, document, [].slice))`,
          body: false,
        },
      ],
    };
  },
  data() {
    return {};
  },
  props: {},
  watch: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss">
</style>
