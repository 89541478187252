<template>
	<v-dialog
		v-model="$store.state.data.application.do_not_sell_my_info_dialog"
		max-width="700px"
		persistent
	>
		<v-card>
			<v-card-actions class="">
				<v-spacer></v-spacer>
				<v-btn
					color="error"
					icon
					@click="
						$store.state.data.application.do_not_sell_my_info_dialog = false
					"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-actions>

			<v-card-text>
				<v-row no-gutters justify="space-around">
					<v-col cols="10" class="my-2">
						<h5>
							<b>
								Are you sure? We use the information you provide to match you
								with insurers.
							</b>
						</h5>
						<h6>
							<p class="text-left">
								We do not sell your info without your approval. Our service is
								free to use, and we have helped millions of users save on
								insurance. To offer our services, we get paid by insurance
								providers when we make a referral. If we are unable to use your
								info when making a referral, we will not be able to properly
								match you. Click Never mind to continue your search for savings
								or "Do not sell my info" to discontinue your use (your
								information will not be sold).
							</p>
						</h6>
					</v-col>
				</v-row>
				<v-row no-gutters justify="space-around">
					<v-col cols="6">
						<div
							style="cursor:pointer"
							class="primary white--text rounded-pill text-center py-2"
							@click="
								($store.state.data.application.do_not_sell_my_info = false),
									($store.state.data.application.do_not_sell_my_info_dialog = false)
							"
						>
							<p class="my-2">
								<b>
									Never Mind, I want quotes
								</b>
							</p>
						</div>
					</v-col>
				</v-row>
				<v-row class="mt-2" no-gutters justify="space-around">
					<v-col cols="6">
						<div
							style="cursor:pointer"
							class="v-btn--outlined orange--text rounded-pill text-center"
							@click="
								($store.state.data.application.do_not_sell_my_info = true),
									($store.state.data.application.do_not_sell_my_info_dialog = false)
							"
						>
							<b>
								Do not sell my info
							</b>
							<br />
							<small class="grey--text darken-4">
								I understand I won't get quotes.
							</small>
						</div>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-text class="text-center">
				For more information see our Privacy Policy.
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
	export default {
		data: () => ({})
	};
</script>
