<template>
  <div>dashboard</div>
</template>

<script>
export default {
  components: {},
  data: () => {
    return {};
  },
  head: {
    title: function () {
      return {
        inner: "Dashboard",
      };
    },
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
</style>
