<template>
	<v-card>
		<div>
			<v-spacer class="mt-12"></v-spacer>
		</div>
		<v-card-text class="pt-12">
			<h2 class="text-center">Rocket Flood Terms of Use</h2>
		</v-card-text>
		<v-card-text class="container">
			<div class="white">
				Terms of Use
				<br />
				Welcome to the services offered by Rocket MGA, LLC DBA Rocket Flood.
				Please read these Terms of Use carefully before accessing or using any
				of the services offered by Rocket MGA. These Terms of Use, together with
				privacy policy (“Privacy Policy”), and the rules, policies, terms and
				conditions set forth in, referred to and/or linked herein, are
				collectively called “Terms,” “Terms of Use,” or “Terms of Services,”
				which are set forth by Rocket MGA LLC and its affiliates (hereinafter
				referred to as Rocket, Rocket Flood, GLV Insurance Agency Inc DBA
				Insurance Express.com, Str8line Enterprises LLC DBA Insurance
				Express.com, “we,” “our” or “us”) and govern the access or use by you of
				applications, websites, content, product, services, and programs made
				available by Rocket Flood (the “Services”). “You” and “Your” means the
				user of the Services. You acknowledge that you have read and understood
				the Terms and by accessing or using the Services you agree to be bound
				by these Terms. Continued use of the Services constitutes your
				acceptance of any revisions to these Terms.
				<br />
				SMS Terms and Conditions
				<br />
				SMS/MMS Mobile Messaging. We offer mobile messaging, which you agree to
				use and participate in subject to these Mobile Messaging Terms and
				Conditions. It is optional for you to participate in the mobile
				messaging. By opting in to or participating in mobile messaging, you
				accept and agree to these terms and conditions, including, without
				limitation, your agreement to resolve any disputes with us through
				arbitration, as detailed in the “Dispute Resolution” section below.
				<br />
				User Opt In: The Mobile Messaging Program allows users to receive SMS
				and MMS mobile messages by affirmatively opting into the Program.
				Regardless of the opt-in method you utilized to join Mobile Messaging,
				you agree that this Agreement applies to your participation in Mobile
				Messaging. By opting in or participating in Rocket Flood mobile
				messaging, you agree to receive messages from us at the phone number
				associated with your opt-in. When you opt in to participate in mobile
				messaging, the messages you receive include the following: a code to
				verify your phone number, flood insurance quotes, responses from our
				customer support team to your inquiries, updates about Rocket Flood
				products or services, at the number associated with your opt-in. Message
				and data rates may apply. Message frequency may vary.
				<br />
				User Opt Out: If you do not wish to continue participating in mobile
				messaging or no longer agree to this Agreement, you agree to reply STOP,
				END, CANCEL, UNSUBSCRIBE, or QUIT to any mobile message from Us in order
				to opt out of the Program. You may receive an additional mobile message
				confirming your decision to opt out. You understand and agree that the
				foregoing options are the only reasonable methods of opting out. You
				also understand and agree that any other method of opting out,
				including, but not limited to, texting words other than those set forth
				above or verbally requesting one of our employees to remove you from our
				list, is not a reasonable means of opting out.
				<br />
				Customer Care Information: For support regarding mobile messaging, text
				“HELP” to the number you received messages from or email us
				quotes@rocketflood.com
				<br />
				Our Disclaimer of Warranty: Mobile messaging is offered on an “as-is”
				basis and may not be available in all areas at all times and may not
				continue to work in the event of product, software, coverage or other
				changes made by wireless carrier. Carriers and we are not liable for any
				delays or failures in the receipt of any mobile messages connected with
				this Program. Delivery of mobile messages is subject to effective
				transmission from your wireless service provider/network operator and is
				outside of our control. Carriers (e.g., T-Mobile, etc.) are not liable
				for delayed or undelivered messages.
				<br />
				Privacy: All information collected from users of our sites is subject to
				our Privacy Policy, which is incorporated by reference into this
				agreement. For more information, see our privacy policy.
				<br />
				Limitations of Use
				<br />
				By accessing and using the Services, you represent and warrant that: (i)
				you are at least 18 years old and are a legal adult in the jurisdiction
				in which you reside; and (ii) you have the right, authority, and
				capacity to enter into this Agreement and to abide by the Terms, and
				that you will so abide. The Services are offered only for your use, and
				not for the use or benefit of others. Your account may not be used for
				Services to be performed for another person. You agree to use the
				Services only for lawful purposes. You may not use the Services in any
				way that would constitute a violation of any applicable law, regulation,
				rule or ordinance of any nationality, state or locality or of any
				international law or treaty, or that could give rise to any civil or
				criminal liability. Any unauthorized use of this Services, including but
				not limited to, unauthorized entry into our systems, misuse of
				passwords, or misuse of any information posted on the Services, is
				strictly prohibited.
				<br />
				Collection and Use of Your Information
				<br />
				We will not share your personal information with third parties without
				your consent. We may ask you to provide personal information that will
				enable us to enhance your use of the Services, such as facilitating
				correspondence with you, or to facilitate or complete transactions. It
				is always your choice whether or not to provide personal information. If
				you choose not to provide requested personal information, you may not be
				able to use certain features of the Services.
				<br />
				Our policies with respect to our collection and use of such information
				are set forth in our privacy policy, which is hereby incorporated by
				reference in its entirety.
				<br />
				Your Authorization When Signing up with Rocket MGA LLC, DBA Rocket Flood
				<br />
				By signing up with Rocket MGA, LLC (“Rocket Flood”), you agree that we
				will shop insurance on your behalf. To shop insurance for you, we may
				ask you to authorize us to obtain and review your insurance coverage
				information by providing your personal information and asking your
				current insurance company or agent to send all your insurance policy
				information to us. Accordingly, you authorize us to send such request to
				your insurance company. During the course of using our services, if you
				have insurance policies with insurance companies we do business with
				that allow for broker of record changes, we may ask you to appoint
				Rocket MGA, LLC DBA Rocket Flood as your new broker of record. We will
				not become your broker of record unless you give Rocket Flood express
				authorization to do so. You agree that we may send communications to
				your insurance provider using your automatically generated e-signature
				as authorization.
				<br />
				Rocket Flood uses affiliate companies and third party partners to
				provide additional quotes for insurance carriers that we do not have
				direct appointments with, as well as quotes for products Rocket Flood
				does not write directly such as Homeowner Insurance, Auto Insurance and
				Life Insurance. We request our partners and those carriers to share with
				us certain information, including: policy number, term length, policy
				premium and effective date, so that we may provide that information to
				you and enhance your user experience on our site. The information will
				be what was provided by the carrier and may or may not be accurate based
				on their processes.
				<br />
				Your Use of the Services
				<br />
				User accounts. In order to use some aspects of the Services, you must
				register for and maintain a user account (“Account”). Account
				registration requires you to submit certain personal information, such
				as your name, email address, street address, and/or mobile phone number,
				etc. You agree to provide and maintain accurate, complete, and
				up-to-date information in your Account. You are responsible for all
				activities that occur under your Account, and you agree to maintain the
				security and secrecy of your Account username and password at all times,
				and do not allow any unauthorized access to your phone, other devices or
				personal information. We are not liable for any loss that you may suffer
				through the use of your password by others. You must notify us
				immediately of any unauthorized use of your Account or of any other
				breach of security known to you with respect to Rocket Flood. You hereby
				authorize Rocket Flood, directly or through third parties, to make any
				inquiries we consider necessary to validate your identity and/or
				authenticate your identity and account information. This may include
				asking you for further information and/or documentation relating to your
				insurance or identity, or requiring you to take steps to confirm
				ownership of your email address, wireless/cellular telephone number or
				other instruments, and verifying your information against third party
				databases or through other sources.
				<br />
				Financial products. Rocket MGA LLC DBA Rocket Flood is a licensed
				insurance agency (Florida Property & Casualty Insurance Agency license
				number L099428). The Services provided by Rocket Flood allow you to view
				financial product offers or quotes from our partners (e.g., insurance
				quotes for your personal insurance from various insurance companies) and
				allow eligible individuals to purchase certain financial products.
				<br />
				By participating as a Rocket Flood user, you accept these Terms of Use
				and specifically consent to let us request and receive your consumer
				report data and other data about you from third parties by utilizing any
				information you provide directly to us or that we are able to obtain
				about you from other reputable sources to include in your Rocket Flood
				account as follows:
				<br />
				I understand that I am providing written instructions in accordance with
				the Fair Credit Reporting Act and other applicable law for Rocket Flood
				or its affiliated and non-affiliated companies to request and receive
				copies of consumer reports, scores and other information about me from
				third parties, including, but not limited to, TransUnion and Equifax. I
				understand that my instructions let Rocket Flood and affiliated and
				non-affiliated companies obtain such information at any time for as long
				as I have a registered Rocket Flood account to use as described in the
				Terms of Use and Privacy Statement. This consent will authorize Rocket
				Flood and its affiliates to conduct a “soft credit” inquiry for the
				purpose of evaluating your prequalification for homeowner & auto
				insurance through our affiliate partners GLV Insurance Agency Inc DBA
				Insurance Express.com, Str8line Enterprises LLC DBA Insurance
				Express.com. This soft credit inquiry will not affect your credit score,
				it will generate an inquiry that is only visible to you.
				<br />
				By using the Services, you authorize us to collect your personal
				information, obtain your insurance policy information including
				declaration pages from insurance companies, or data providers providing
				such info, shop insurance, purchase and bind new insurance policies,
				cancel insurance policies that you no longer need, request refunds,
				speak to insurance companies and/or perform other actions on your
				behalf. We do not provide forms of professional advice of the type that
				may require additional professional licensing, such as legal, medical,
				accounting, or tax advice. Further, the information we provide is not
				insurance advice. Any insurance purchasing decisions such as coverages
				(e.g., types and amounts), limits, and deductibles are solely the
				responsibility of the insured. We have no special relationship with or
				fiduciary duty to you, and RocketFlood is not responsible for
				determining the suitability of insurance coverages acquired through the
				Services. You will review your policy documentation, review the price
				quotes for insurance product(s) carefully, and determine the insurance
				coverages you need, prior to making any decision to purchase insurance
				product(s) through the Services. You acknowledge that we have no duty to
				determine or take any action regarding: (a) who should be insured on
				your policy or policies; (b) what type of coverage(s) and what amount of
				coverage(s) should be on your policy or policies; (c) what property or
				properties should be on your policy or policies.
				<br />
				The offers, quotes or premium amount(s) for financial product(s) quoted
				do not constitute a contract or an invitation to contract or a credit
				application, or a binder or agreement to extend, continue, or renew
				insurance coverage, and may be subject to change based on, for example,
				verification of claim and credit history where permitted by law,
				property inspection(s), or any other of insurance company’s underwriting
				criteria, as applicable. During the course of using our services, you
				may request your insurance company to make us broker of record, you
				agree that the effect of such request is not confirmed until the
				insurance company lists us as the broker of record and lists / shares
				your policy information with us as your broker of record. If you
				authorize Rocket Flood or our affiliates GLV Insurance Agency Inc DBA
				Insurance Express.com, Str8line Enterprises LLC DBA Insurance
				Express.com to become your broker of record (which may come in effect on
				the day of you signing up with Rocket Flood or your policy renewal
				date), such appointment remains in effect until canceled in writing and
				rescinding all previous appointment(s). You permit Rocket Flood or our
				affiliates GLV Insurance Agency Inc DBA Insurance Express.com, Str8line
				Enterprises LLC DBA Insurance Express.com to receive commission or any
				other form of compensation that third party providers such as insurance
				companies or carriers agree to pay to Rocket Flood or our affiliates GLV
				Insurance Agency Inc DBA Insurance Express.com, Str8line Enterprises LLC
				DBA Insurance Express.com for your use of the Services.
				<br />
				To be safe, you should not cancel any existing insurance until you have
				received written confirmation from the insurance company to which you
				are applying that your new policy is effective.
				<br />
				Communications. You expressly consent and agree to receive
				communications from us (e.g., communications relating to products,
				services, and marketing, etc.), including by phone or electronically,
				e.g., email, calls, Facebook Messenger, push notifications, app(s), and
				Rocket Flood’s websites, to the telephone number and email address you
				provided to us, and accounts you set up with Rocket Flood. You consent
				to using the email address, phone number or app(s) to receive
				service-related notices or communications, including any notices or
				communications required by law, instead of postal mail. As used in this
				section, “we” or “us” refers not just to Rocket Flood, but also Rocket
				Flood’s agents, and affiliates. You further agree that we may accept an
				electronic authorization from you, and that this electronic
				authorization will have the same effect as a physical wet signature and
				will appear on all records related to the provision of products and
				services. Your consent is voluntary and applies to all Communications
				and information that we receive from you.
				<br />
				User content. You may submit, upload, publish or otherwise make
				available textual, audio, and/or visual content and information,
				including commentary and feedback related to the Services, and questions
				you ask us in the “Live Chat” features of the Services (“User Content”).
				Any User Content provided by you remains your property. However, by
				providing User Content to us, you grant us a worldwide, perpetual,
				irrevocable, transferable, unrestricted, non-exclusive, absolute,
				royalty-free license, with the right to sublicense, to use, copy,
				modify, transmit, reproduce, exhibit, disclose, display, transform,
				create derivative works of, distribute, publicly display, publicly
				perform, and otherwise exploit in any manner such User Content in all
				formats and distribution channels now known or hereafter devised,
				without further notice to or consent from you, and without the
				requirement of payment to you or any other person or entity. Further, we
				shall be free to use any ideas, concepts, know-hows or techniques
				contained in such User Content for any purpose whatsoever, including
				developing, manufacturing and marketing products and/or services
				incorporating any User Content. You represent and warrant that: (i) you
				either are the sole and exclusive owner of all User Content or you have
				all rights, licenses, consents and releases necessary to grant us the
				license to the User Content as set forth above; and (ii) neither the
				User Content nor your submission, uploading, publishing or otherwise
				making available of such User Content nor our use of the User Content as
				permitted herein will infringe, misappropriate or violate a third
				party’s intellectual property or proprietary rights, or rights of
				publicity or privacy, or result in the violation of any applicable law
				or regulation. You agree to not provide User Content that is defamatory,
				libelous, hateful, violent, obscene, pornographic, unlawful, or
				otherwise offensive, as determined by us in our sole discretion, whether
				or not such material may be protected by law. We may, but shall not be
				obligated to, review, monitor, or remove User Content, at our sole
				discretion and at any time and for any reason or no reason, without
				notice to you.
				<br />
				Termination
				<br />
				If you wish to terminate your Account, you may do so by sending us a
				written notice by email (legal@rocketflood.com) or U.S. mail (2005 Vista
				Parkway, Suite 200, West Palm Beach FL, 33411, Attn: Legal). We may
				terminate your access to all or any part of the Services at any time,
				with or without cause, with or without notice. All provisions of these
				Terms of Service which by their nature should survive termination shall
				survive termination, including, without limitation, licenses of User
				Content, ownership provisions, disclaimers, indemnity and limitations of
				liability.
				<br />
				Your Obligations and Authorizations
				<br />
				By registering with Rocket Flood, you acknowledge and authorize Rocket
				Flood and its authorized agents to (i) collect information relating to
				you through the Services, including certain personal information,
				signatures, data, passwords, usernames, and other information,
				materials, and content necessary for us to provide the products and
				services offered through the Services to you, (ii) retrieve and manage
				information of yours maintained by certain third-party institutions,
				including insurance companies, data providers, and service providers
				(“Your Data”), and (iii) act in any way reasonably necessary to
				accomplish the goal of shopping for financial products (e.g., insurance)
				for you subject to any instructions provided by you in advance. You
				hereby grant us a non-exclusive, royalty-free license under any
				applicable intellectual property rights to use Your Data solely in
				connection with the provision of the products and services offered
				through the Services, and you authorize Rocket Flood to access your
				connected account(s) and download relevant information in order to
				provide the Services. To withdraw this authorization, you may cancel and
				delete your account at any time by emailing us legal@rocketflood.com.
				<br />
				You represent and warrant that Your Data that you provide us is accurate
				and complete, and that you are authorized to submit it to us without any
				obligations on us, including but not limited to the payment of fees. We
				have no liability or other responsibility for inaccuracy or
				incompleteness, or your inability to use the Services due to such
				inaccuracy or incompleteness. You are and will be responsible for the
				consequences of any instructions you provide us and that we follow. In
				order to connect the Services with any third-party service, you hereby
				authorize us to: (i) store Your Data in accordance with applicable laws,
				(ii) use any signatures or other materials you provide us in order to
				provide you the products and services offered through the Services,
				(iii) gather and export from such third party service any data or other
				information reasonably necessary for us to provide the products and
				services offered through the Services to you, and (iv) otherwise take
				any action in connection with such third party service as reasonably
				necessary for us to provide the products and services offered through
				the Services to you. You agree that those third-party service providers
				are entitled to rely on the foregoing authorization, agency, and power
				of attorney granted by you. While the Services may be endorsed by the
				third-parties with whom Your Data is held from time to time, you should
				not assume that we are working directly with the third-party or that the
				third-party has sponsored or endorsed the Services or our interaction
				with the third-party’s services at your direction. You also understand
				you are providing written instructions in accordance with the Fair
				Credit Reporting Act and other applicable law for us or our authorized
				representatives to request and receive copies of consumer reports,
				scores, and related information about you from third parties. We or our
				authorized representatives may release information to our partners,
				affiliates, insurance companies, or others.
				<br />
				Third-Party Practices
				<br />
				This Services contains links to other websites or services that are not
				maintained by us. Unless otherwise stated, we make no warranties or
				representations regarding these websites or the products and services
				offered on them. We do not control the privacy or security practices of
				third parties, or the locations where they process data. You should read
				the privacy and security policies of the other sites, as their practices
				may differ from ours.
				<br />
				Intellectual Property
				<br />
				Our Service and its original content, features and functionality are and
				will remain the exclusive property of Rocket Flood and its licensors.
				The Services are protected by copyright, trademark and other laws of
				both the United States and foreign countries. The term “Rocket Flood” in
				the context of the Services offered by us and the related logos are the
				trademarks of Rocket Flood. Our trademarks and trade dress may not be
				used in connection with any product or service without the prior written
				consent of Rocket Flood.
				<br />
				Disclaimer
				<br />
				THE SERVICE ARE MADE BY US ON AN “AS IS,” “AS AVAILABLE,” AND “WITH ALL
				FAULTS” BASIS, WITHOUT ANY WARRANTIES OR CONDITIONS, EXPRESS, IMPLIED OR
				STATUTORY. YOU USE THE SERVICES ENTIRELY AT YOUR OWN RISK. WE DO NOT
				PROVIDE, AND SPECIFICALLY DISCLAIM, ANY REPRESENTATION OR WARRANTY OF
				ANY KIND TO YOU OR ANY OTHER PERSON OR ENTITY, INCLUDING, BUT NOT
				LIMITED TO, ANY EXPRESS OR IMPLIED WARRANTY (I) OF MERCHANTABILITY OR
				FITNESS FOR A PARTICULAR PURPOSE; (II) OF INFORMATIONAL CONTENT OR
				ACCURACY; (III) OF NON-INFRINGEMENT; (IV) OF QUIET ENJOYMENT; (V) OF
				TITLE; (VI) THAT ROCKET FLOOD WILL OPERATE ERROR FREE, OR IN AN
				UNINTERRUPTED FASHION; (VII) THAT ANY DEFECTS OR ERRORS IN THE ROCKET
				FLOOD PRODUCT OR SERVICES WILL BE CORRECTED; (VIII) THAT SERVICES
				OFFERED WILL BE AVAILABLE TO ANYONE; OR (IX) THAT ROCKET FLOOD PRODUCTS
				OR SERVICES ARE COMPATIBLE WITH ANY PARTICULAR HARDWARE OR SOFTWARE.
				ROCKET FLOOD DOES NOT GUARANTEE THE QUALITY, SUITABILITY, SAFETY OR
				ABILITY OF THIRD PARTY PROVIDERS OR THEIR GOODS OR SERVICES. YOU AGREE
				THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND ANY
				SERVICE OR GOOD REQUESTED IN CONNECTION THEREWITH, REMAINS SOLELY WITH
				YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.
				<br />
				Limitations of Liability
				<br />
				YOU AGREE NOT TO HOLD ROCKET MGA LLC DBA ROCKET FLOOD (OR, ITS
				STOCKHOLDERS, AFFILIATES, LICENSORS, PARTNERS, MEMBERS, DIRECTORS,
				MANAGERS, OFFICERS, EMPLOYEES, AGENTS, SUCCESSORS, ASSIGNS AND CONTENT
				PROVIDERS (COLLECTIVELY, “MEMBERS”)) LIABLE FOR ANY DAMAGES, EXPENSES,
				LOSSES, SUITS, CLAIMS, AND/OR CONTROVERSIES THAT HAVE ARISEN OR MAY
				ARISE, WHETHER KNOWN OR UNKNOWN, RELATING TO YOUR USE OF OR INABILITY TO
				USE THE ROCKET FLOOD SERVICES, INCLUDING WITHOUT LIMITATION ANY
				LIABILITIES ARISING IN CONNECTION WITH THE CONDUCT, ACT, DELAY OF
				PERFORMANCE OR OMISSION OF ANY USER OR THIRD PARTY PROVIDER (OR ANY
				TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY THIRD PARTY PROVIDER),
				ANY DISPUTE WITH ANY USER OR THIRD PARTY PROVIDER, ANY INSTRUCTION,
				ADVICE, ACT, OR SERVICE PROVIDED BY ROCKET FLOOD OR MEMBERS, AND ANY
				DESTRUCTION OF YOUR INFORMATION.
				<br />
				UNDER NO CIRCUMSTANCES WILL ROCKET FLOOD OR MEMBERS BE LIABLE (1) FOR
				ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE OR
				EXEMPLARY DAMAGES, HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY,
				WHETHER IN AN ACTION FOR CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING
				NEGLIGENCE) OR OTHERWISE, INCLUDING WITHOUT LIMITATION LOST PROFITS,
				LOST EARNINGS, LOST DATA, PERSONAL INJURY, OR PROPERTY DAMAGE, WHETHER
				OR NOT WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, (2) FOR
				ANY VIRUSES, BUGS, TROJAN HORSES, SYSTEM UPGRADES OR MALFUNCTIONS,
				INTERNET DELAYS OR MALFUNCTIONS, OR THE LIKE (REGARDLESS OF THE SOURCE
				OF ORIGINATION), OR (3) FOR ANY DIRECT DAMAGES IN EXCESS OF (IN THE
				AGGREGATE) OF THE GREATER OF (A) FEES PAID TO US FOR THE PARTICULAR
				SERVICES DURING THE IMMEDIATELY PRECEDING THREE (3) MONTH PERIOD OR (B)
				\$500.00. ROCKET FLOOD AND MEMBERS DO NOT ACCEPT ANY LIABILITY WITH
				RESPECT TO THE QUALITY OR FITNESS OF ANY WORK PERFORMED IN CONNECTION
				WITH THE ROCKET FLOOD SERVICES. THE ROCKET FLOOD SERVICES CONNECTS USERS
				WITH THIRD PARTY PROVIDERS SUCH AS INSURANCE COMPANIES OR CARRIERS. WE
				ARE NOT RESPONSIBLE FOR ASSESSING THE SUITABILITY, LEGALITY OR ABILITY
				OF ANY THIRD PARTY PROVIDER AND YOU EXPRESSLY WAIVE AND RELEASE ROCKET
				FLOOD FROM ANY AND ALL LIABILITY, CLAIMS OR DAMAGES (ACTUAL, DIRECT OR
				CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, SUSPECTED
				AND UNSUSPECTED, DISCLOSED AND UNDISCLOSED, ARISING FROM OR IN ANY WAY
				RELATED TO ANY THIRD PARTY PROVIDER. WE EXPRESSLY DISCLAIM ANY LIABILITY
				THAT MAY ARISE BETWEEN YOU AND ANY THIRD PARTY PROVIDER. THE QUALITY OF
				THE SERVICES PROVIDED BY THIRD PARTY PROVIDER IS ENTIRELY THE
				RESPONSIBILITY OF THE THIRD PARTY PROVIDER. YOU UNDERSTAND, THEREFORE,
				THAT BY USING THE ROCKET FLOOD SERVICES, YOU USE THE ROCKET FLOOD
				SERVICES, AND THIRD PARTY PROVIDER, AT YOUR OWN RISK. NOTHING IN THIS
				AGREEMENT OR THE ROCKET FLOOD SERVICES CONSTITUTES, OR IS MEANT TO
				CONSTITUTE, ADVICE OF ANY KIND. IF YOU REQUIRE ADVICE IN RELATION TO ANY
				LEGAL, FINANCIAL OR MEDICAL MATTER YOU SHOULD CONSULT AN APPROPRIATE
				PROFESSIONAL.
				<br />
				SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN
				TYPES OF DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. IF,
				NOTWITHSTANDING THE FOREGOING EXCLUSIONS, IT IS DETERMINED THAT ROCKET
				FLOOD OR MEMBERS ARE LIABLE FOR DAMAGES, IN NO EVENT WILL THE AGGREGATE
				AND TOTAL LIABILITY, WHETHER ARISING IN CONTRACT, TORT, STRICT LIABILITY
				OR CAUSE OF ACTION, EXCEED THE AMOUNT INVOICED IN THE PREVIOUS 12
				MONTHS.
				<br />
				BY USING THE ROCKET FLOOD SERVICES, YOU AGREE THAT THE EXCLUSIONS AND
				LIMITATIONS OF LIABILITY SET OUT IN THIS AGREEMENT ARE REASONABLE. IF
				YOU DO NOT BELIEVE THEY ARE REASONABLE, YOU MUST NOT USE THE ROCKET
				FLOOD SERVICES.
				<br />
				California Waiver. If you are a California resident, to the extent
				permitted by California law, you waive California Civil Code Section
				1542, which states: “A general release does not extend to claims which
				the creditor does not know or suspect to exist in his favor at the time
				of executing the release, which if known by him must have materially
				affected his settlement with the debtor” as well as any other unknown
				claims under California Civil Code Section 1542 or any statute or common
				law principle of similar effect.
				<br />
				Indemnification. You hereby agree to indemnify, defend, and hold
				harmless Rocket Flood and Members from and against any and all claims,
				losses, expenses, liabilities, damages or demands (including attorneys’
				fees and costs incurred), in connection with or resulting from, directly
				or indirectly: (i) your use or misuse of or inability to use the Rocket
				Flood Services, (ii) your violation of this Agreement, (iii) your
				violation of any applicable law or regulation; and (iv) your information
				and content that you submit or transmit through the Rocket Flood
				Services. Rocket Flood reserves the right, at its own expense, to assume
				the exclusive defense and control of any matter otherwise subject to
				your indemnification. You will not, in any event, settle any such claim
				or matter without the prior written consent of Rocket Flood.
				<br />
				Dispute Resolution
				<br />
				Arbitration. Any dispute or claim relating in any way to your use of the
				Rocket Flood Services will be resolved by binding arbitration, rather
				than in court, except that you may assert claims in small claims court
				if your claims qualify. The Federal Arbitration Act and federal
				arbitration law apply to this Agreement. There is no judge or jury in
				arbitration, and court review of an arbitration award is limited.
				However, an arbitrator can award on an individual basis the same damages
				and relief as a court (including injunctive and declaratory relief or
				statutory damages), and must follow the terms of these Terms of Use as a
				court would. To begin an arbitration proceeding, you must send a letter
				requesting arbitration and describing your claim to Rocket MGA LLC DBA
				Rocket Flood’s Registered Agent/Legal Department, located at 2005 Vista
				Parkway, Suite 200, West Palm Beach FL 33411. The arbitration will be
				conducted in Miami, Florida, by the American Arbitration Association
				(AAA) under its rules, including the AAA’s Consumer Arbitration Rules.
				The AAA’s rules are available at www.adr.org or by calling
				1-800-778-7879. Payment of all filing, administration and arbitrator
				fees will be governed by the AAA’s rules. We will reimburse those fees
				for claims totaling less than \$10,000 unless the arbitrator determines
				the claims are frivolous. Likewise, Rocket Flood will not seek
				attorneys’ fees and costs in arbitration unless the arbitrator
				determines the claims are frivolous. You may choose to have the
				arbitration conducted by telephone, based on written submissions, or in
				person in the county where you live or at another mutually agreed
				location.
				<br />
				We each agree that any dispute resolution proceedings will be conducted
				only on an individual basis and not in a class, consolidated or
				representative action. If for any reason a claim proceeds in court
				rather than in arbitration we each waive any right to a jury trial. We
				also both agree that you or we may bring suit in court to enjoin
				infringement or other misuse of intellectual property rights.
				<br />
				Applicable law. By accessing or using the Rocket Flood Services, you
				agree that the Federal Arbitration Act, applicable federal law, and the
				laws of the State of Florida, without giving effect to any choice of
				laws principles that would require the application of the laws of a
				different country or state, will govern these Terms of Use and any
				dispute of any sort that might arise between you and Rocket Flood.
				<br />
				Other Provisions
				<br />
				General. You may not assign these Terms without Rocket Flood’s prior
				written approval. Rocket Flood may assign these Terms without your
				consent to: (i) a subsidiary or affiliate; (ii) an acquirer of Rocket
				MGA LLC DBA Rocket Flood’s equity, business or assets; or (iii) a
				successor by merger. Any purported assignment in violation of this
				section shall be void. If any provision of these Terms is held to be
				invalid or unenforceable, such provision shall be struck and the
				remaining provisions shall be enforced to the fullest extent under law.
				Rocket Flood’s failure to enforce any right or provision in these Terms
				shall not constitute a waiver of such right or provision unless
				acknowledged and agreed to by Rocket Flood in writing.
				<br />
				Complaints of copyright infringement. We respect the intellectual
				property of others. We will respond to properly submitted notices of
				alleged copyright infringement that comply with applicable law. In the
				event that a person or entity has a good faith belief that any materials
				provided through the Rocket Flood Services infringe upon that person’s
				or entity’s copyright or other intellectual property right (such person
				or entity, a “Complainant”) and sends to us a properly submitted
				copyright notice as indicated below, we will investigate, and if we
				determines, in our discretion, that the material is infringing, we will
				remove the content. All notices claiming an infringement of copyright
				rights must contain the following: (i) identification of the
				intellectual property right that is allegedly infringed (all relevant
				registration numbers, or a statement concerning the Complainant’s
				ownership of the work, should be included); (ii) a statement
				specifically identifying the location of the infringing material, with
				enough detail that we may find it (please note it is not sufficient to
				merely provide a top level URL); (iii) the complete name, address,
				telephone number and email address of Complainant; (iv) a statement that
				Complainant has a good faith belief that the use of the allegedly
				infringing material is not authorized by the owner of the rights, or its
				agents, or by law; (v) a statement that the information contained in the
				notification is accurate, and under the penalty of perjury, Complainant
				is authorized to act on behalf of the owner of the copyright or other
				property rights that are allegedly infringed; and (vi) a physical or
				electronic signature of a person authorized to act on behalf of the
				owner of the intellectual property rights that are allegedly being
				infringed. Rocket Flood’s contact information for notice of alleged
				copyright infringement is via email: legal@rocketflood.com or via U.S.
				mail: 2005 Vista Parkway, Suite 200, West Palm Beach FL 33411, Attn:
				Legal.
				<br />
				Notice. Rocket Flood may give notice by means of a general notice
				through the Rocket Services, electronic mail to your email address in
				your Account, or by written communication sent by first class mail or
				pre-paid post to your address in your Account. Such notice shall be
				deemed to have been given upon the expiration of 48 hours after mailing
				or posting (if sent by first class mail or pre-paid post) or 12 hours
				after sending (if sent by email). You may give notice to Rocket Flood,
				with such notice deemed given when received by Rocket Flood, at any time
				by first class mail to: 2005 Vista Parkway, Suite 200, West Palm Beach
				FL 33411, Attn: Legal.
				<br />
				Contacting Us
				<br />
				If you have any questions regarding this privacy policy or our
				practices, please contact us by email (legal@rocketflood.com) or by U.S.
				mail (2005 Vista Parkway, Suite 200, West Palm Beach FL 33411, Attn:
				Legal).
				<br />
				<br />
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
	import goTo from "vuetify/lib/services/goto";
	export default {
		data() {
			return {};
		},
		head: {
			title: function() {
				return {
					inner: "Terms Of Use"
				};
			}
		},
		watch: {},
		mounted() {
			goTo(0);
		},
		methods: {}
	};
</script>
