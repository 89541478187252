var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"section"},[_c('div',{staticClass:"html-embed-5 w-embed",staticStyle:{"background-color":"white","z-index":"99","top":"5px","left":"2px"},on:{"click":function($event){return _vm.pageBack()}}},[_vm._m(0)]),_c('div',{staticStyle:{"top":"10px","position":"relative","opacity":"0"},style:(['sm', 'xs'].includes(_vm.$vuetify.breakpoint.name)
						? 'width: 70px;'
						: 'width: 167px;')}),_c('v-spacer'),_c('img',{staticClass:"image-1 py-3 px-0",staticStyle:{"cursor":"pointer"},style:(['sm', 'xs'].includes(_vm.$vuetify.breakpoint.name)
						? 'max-width:160px;'
						: 'max-width:360px;'),attrs:{"src":'/images/5d9a33664aefbeb0b6970a93_RocketFlood-Logo.svg',"alt":"logo"},on:{"click":function($event){return _vm.back()}}}),_c('v-spacer'),_c('a',{attrs:{"href":("tel:" + _vm.companyPhoneNumber)},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openCallNow()}}},[_c('div',{staticClass:"nav-link-agent",staticStyle:{"display":"inline-block !important","min-height":"50px"}},[_c('div',{staticClass:"header-phone-number",staticStyle:{"display":"block !important","opacity":"1","color":"#4a4a4a !important"},style:(['sm', 'xs'].includes(_vm.$vuetify.breakpoint.name)
								? 'display:none !important;'
								: '')},[_vm._v(" "+_vm._s(_vm.companyPhoneNumber)+" ")])])])],1),_c('div',{staticClass:"section-2"},[_c('div',{class:['1', '2', '3', '4', '5', '6', '7', '8'].includes(
						Math.floor(_vm.currentPageNumber).toString()
					)
						? 'div-block-9'
						: 'div-block-9-white'}),_c('div',{class:['2', '3', '4', '5', '6', '7', '8'].includes(
						Math.floor(_vm.currentPageNumber).toString()
					)
						? 'div-block-9'
						: 'div-block-9-white'}),_c('div',{class:['3', '4', '5', '6', '7', '8'].includes(
						Math.floor(_vm.currentPageNumber).toString()
					)
						? 'div-block-9'
						: 'div-block-9-white'}),_c('div',{class:['4', '5', '6', '7', '8'].includes(
						Math.floor(_vm.currentPageNumber).toString()
					)
						? 'div-block-9'
						: 'div-block-9-white'}),_c('div',{class:['5', '6', '7', '8'].includes(
						Math.floor(_vm.currentPageNumber).toString()
					)
						? 'div-block-9'
						: 'div-block-9-white'}),_c('div',{class:['6', '7', '8'].includes(Math.floor(_vm.currentPageNumber).toString())
						? 'div-block-9'
						: 'div-block-9-white'}),_c('div',{class:['7', '8'].includes(Math.floor(_vm.currentPageNumber).toString())
						? 'div-block-9'
						: 'div-block-9-white'}),_c('div',{class:['8'].includes(Math.floor(_vm.currentPageNumber).toString())
						? 'div-block-9'
						: 'div-block-9-white'})]),_c('router-view')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('i',{staticClass:"far fa-arrow-alt-circle-left fa-3x"})])}]

export { render, staticRenderFns }