<template>
	<div>
		<header
			:style="
				!headerSmall && offsetTop < 40 ? '' : 'border-bottom: 1px solid gray;'
			"
			style="background-color: white !important"
			class="header-section py-2 px-2"
		>
			<nav class="pt-2">
				<a
					style="cursor: pointer"
					class="px-3"
					color="rgba(74, 74, 74, 0.71)"
					@click.stop="
						$route.name != 'home' ? $router.push({ name: 'home' }) : ''
					"
				>
					<span class="home-icon"></span>
				</a>
				<a
					style="cursor: pointer"
					class="px-3"
					@click.stop="
						$route.name != 'about' ? $router.push({ name: 'about-us' }) : ''
					"
					>About Us</a
				>
				<a
					style="cursor: pointer"
					class="px-3"
					@click.stop="
						$route.name != 'faq' ? $router.push({ name: 'faq' }) : ''
					"
					>FAQs</a
				>
				<a
					style="cursor: pointer"
					href="#"
					class="brand w-nav-brand"
					:class="
						!headerSmall && offsetTop < 40
							? 'main-logo-large'
							: 'main-logo-small'
					"
				>
					<img
						src="@/assets/images/5d9a33664aefbeb0b6970a93_RocketFlood-Logo.svg"
						alt
					/>
				</a>
				<a
					:href="`tel:${companyPhoneNumber}`"
					@click.prevent.stop="openCallNow()"
				>
					<div class="nav-link-agent">
						<div class="header-phone-number" style="color: #4a4a4a !important">
							{{ companyPhoneNumber }}
						</div>
					</div>
				</a>
			</nav>
		</header>

		<router-view />

		<div class="sperator"></div>
		<div class="secondary-cta-section">
			<div class="secondary-cta-flex-block">
				<h2 class="heading-6">
					Secure the most competitive flood insurance obtainable.
				</h2>
				<p>
					In under 3 minutes our clients save up to 40%
					<br />
				</p>
				<div class="cta-wrapper-2">
					<div class="form-block-01 w-form">
						<div id="email-form" name="email-form" class="form transparent">
							<!-- <vue-google-autocomplete
								ref="address"
								id="map-bottom"
								classname="text-field w-input"
								placeholder="Enter Address, City, State, Zip"
								v-on:placechanged="getAddressData"
								country="us"
							>
							</vue-google-autocomplete>
							<input
								@click="addressAdded"
								type="button"
								value="Get A Quote"
								class="form-cta w-button"
							/> -->
							<Autocomplete
								:key="'footer-search-bar'"
								@searched_address="val => (inputAddress = val)"
							></Autocomplete>
							<!-- @click="$router.push({ name: 'getAQuoteAddress' })" -->
							<input
								@click="inputAddress ? addressAdded() : null"
								type="button"
								value="Get A Quote"
								class="form-cta w-button"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="sperator"></div>
		<div class="footer-section">
			<div class="footer-flex-block">
				<div class="footer-col-01">
					<img
						src="@/assets/images/5d9be876787cd06ffb3d04d2_Reverse-Logo.svg"
						alt
					/>
				</div>
				<div class="footer-col">
					<a
						@click.stop="
							$route.name != 'home' ? $router.push({ name: 'home' }) : ''
						"
						href="#"
						class="footer-link w-inline-block w--current"
					>
						<div>Home</div>
					</a>
					<a
						@click.stop="
							$route.name != 'about' ? $router.push({ name: 'about-us' }) : ''
						"
						href="#"
						class="footer-link w-inline-block"
					>
						<div>About Us</div>
					</a>
					<a
						@click.stop="
							$route.name != 'faq' ? $router.push({ name: 'faq' }) : ''
						"
						href="#"
						class="footer-link w-inline-block"
					>
						<div>FAQs</div>
					</a>
					<a href="#" class="footer-link w-inline-block">
						<div>Request A Quote</div>
					</a>
				</div>
				<div class="footer-col">
					<p class="footer-p-rename">Address</p>
					<p class="footer-p">
						2005 Vista Parkway, Suite 200 West Palm Beach, FL 33411
					</p>
					<p class="footer-p-rename">Phone Number</p>
					<a
						:href="`tel:${companyPhoneNumber2}`"
						@click.prevent.stop="openCallNow()"
					>
						<p class="footer-p">{{ companyPhoneNumber2 }}</p>
					</a>
				</div>
				<div class="footer-col">
					<p class="footer-p-rename">Legal</p>
					<a
						:href="`#`"
						@click.prevent.stop="
							$route.name != 'privacy-policy'
								? $router.push({ name: 'privacy-policy' })
								: ''
						"
					>
						<p class="footer">Privacy Policy</p>
					</a>
					<a
						:href="`#`"
						@click.prevent.stop="
							$route.name != 'terms-of-use'
								? $router.push({ name: 'terms-of-use' })
								: ''
						"
					>
						<p class="footer">Term Of Use</p>
					</a>
					<a
						:href="`#`"
						@click.prevent.stop="
							$route.name != 'licenses'
								? $router.push({ name: 'licenses' })
								: ''
						"
					>
						<p class="footer">Licenses</p>
					</a>
					<a
						:href="`#`"
						@click.prevent.stop="
							$store.state.data.application.do_not_sell_my_info_dialog = true
						"
					>
						<p class="footer">Do Not Sell My Info</p>
					</a>
				</div>
			</div>
			<div class="footer-flex-block-02">
				<div class="bottom-footer-col">
					<p class="bottom-footer-p">
						Copyright © 2019. Rocket Flood. All rights reserved.
						<span class="hide">|</span>
						<a
							@click.prevent.stop="
								$route.name != 'privacy-policy'
									? $router.push({ name: 'privacy-policy' })
									: ''
							"
							href="#"
							class="footer-text-link hide"
							>Privacy Policy</a
						>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Autocomplete from "@/components/address-autocomplete/Autocomplete";
	// import VueGoogleAutocomplete from "vue-google-autocomplete";
	export default {
		components: { Autocomplete },
		data() {
			return {
				inputAddress: "",
				addressList: []
			};
		},
		props: {
			headerSmall: { required: false, default: false, type: Boolean }
		},
		watch: {},
		created() {
			// var scripts = [
			//   "",
			// ];
			// scripts.forEach((script) => {
			//   let tag = document.createElement("script");
			//   tag.setAttribute("src", script);
			//   document.head.appendChild(tag);
			// });
		},
		methods: {
			openCallNow() {
				return (window.location.href = `tel:${this.companyPhoneNumber2}`);
			},
			getAddressData: function(addressData, placeResultData, id) {
				this.inputAddress = placeResultData.formatted_address;
			},
			async addressAdded() {
				if (this.inputAddress) {
					this.$store.state.data.application.address = this.inputAddress;
					this.getPropertyDetailsFromAttomApi(this.inputAddress).then(val => {
						// console.log(val && val.code == 200 && !!val.data.data);
						if (val && val.code == 200 && !!val.data.data) {
							let details = val.data.data;
							this.$store.state.data.application.addr1 = details.address.line1;

							this.$store.state.data.application.city = details.address.locality;

							this.$store.state.data.application.state =
								details.address.countrySubd;

							this.$store.state.data.application.country =
								details.address.country;

							this.$store.state.data.application.zip = details.address.postal1;

							this.$store.state.data.application.yearBuilt = details.summary
								.yearBuilt
								? `${details.summary.yearBuilt}`
								: "";

							this.$store.state.data.application.sqft = details.building.size
								.livingSize
								? `${details.building.size.livingSize}`
								: "";

							this.$store.state.data.application.numberOfStories = details
								.building.summary.levels
								? `${details.building.summary.levels}`
								: `1`;

							this.$store.state.data.application.constructionType = details
								.building.construction.frameType
								? `${details.building.construction.frameType.toLowerCase()}`
								: "";

							if (
								![null, ""].includes(details.address.line1) &&
								![null, ""].includes(details.address.city) &&
								![null, ""].includes(details.address.state) &&
								![null, ""].includes(details.address.zip)
							) {
								this.$store.state.data.application.home_detail_received_from_api = true;
							}
						}
						this.$router.push({ name: "getAQuoteAddress" });
					});
				} else {
					this.$router.push({ name: "getAQuoteAddress" });
				}

				// console.log(length, country, state, city, line);
				// this.$router.push({ name: "getAQuoteAddress" });
			}
		}
	};
</script>

<style lang="scss">
	body {
		margin: 0;
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
			"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
			"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		color: #212529;
		text-align: left;
		background-color: #fff;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.main-headline,
	.heading-1,
	.heading-2,
	.heading-3,
	.heading-4,
	.heading-5,
	.heading-6,
	.heading-7,
	.heading-8,
	.primary-heading--text {
		color: #4a4a4a !important;
	}
	.header-section {
		background-color: #fff;
		width: 100%;
		> * {
			font-size: 0.8em;
			font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
				Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
				Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
		}
		a {
			// color: rgba(74, 74, 74, 0.71) !important;
			color: #222222 !important;
		}

		a:hover {
			color: #007bff !important;
		}
		nav {
			width: 100%;
		}
	}
	.main-logo-small img,
	.main-logo-large img {
		width: 100%;
		height: auto;
	}
	.main-logo-large {
		will-change: transform;
		transform: translate3d(0px, 39px, 0px) scale3d(1.5, 1.5, 1) rotateX(0deg)
			rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
		transform-style: preserve-3d;
	}
	.main-logo-small {
		top: 130px !important;
		will-change: transform;
		transform: translate3d(0px, -120px, 0px) scale3d(0.93, 0.93, 1) rotateX(0deg)
			rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
		transform-style: preserve-3d;
	}

	@media (max-width: 991px) {
		.header-section nav a {
			padding: 0 !important;
			padding-left: 4px !important;
			padding-right: 4px !important;
		}
		.main-logo-large {
			top: 50% !important;
		}
		.main-logo-small {
			top: 120px !important;
			right: 20px !important;
			width: 180px !important;
			margin-right: 0 !important;
		}

		.flex-block-full-width-02 {
			padding-top: 0px !important;
			font-size: 1rem !important;
			.main-headline {
				font-size: 1.7rem !important;
			}
			p {
				font-size: 0.9rem !important;
			}
			.cta-wrapper-02 {
				margin-top: 20px;
				padding: 0 !important;
				.form-block-01 {
				}
			}
		}
	}

	@media (max-width: 479px) {
	}
</style>
