import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

import "./plugins/base";

import auth from "./plugins/auth";
import helpers from "./plugins/helpers";
import Mixins from "./plugins/Mixins";

import VueHead from "vue-head";
import Notifications from "vue-notification";

import getAQuote from "./views/layouts/getAQuote";
import Main from "./views/layouts/main";

Vue.component("get-a-Quote-layout", getAQuote);
Vue.component("main-layout", Main);

import VueMask from "v-mask";
Vue.use(VueMask);

Vue.mixin(Mixins);

Vue.use(Notifications);
Vue.use(VueHead);

window.auth = auth;
window.helpers = helpers;

Vue.prototype.$helpers = helpers;
Vue.config.productionTip = false;

const init = () => {
  auth.initAuth();
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
};

document.addEventListener("deviceready", () => {
  init();
});

const isCordovaApp = typeof window.cordova !== "undefined";

if (!isCordovaApp) {
  document.dispatchEvent(new CustomEvent("deviceready", {}));
}
