/* eslint-disable prettier/prettier */
class Helper {
  constructor() {}
  isJson(str) {
    if (typeof str !== "string") return false;
    try {
      const result = JSON.parse(str);
      const type = Object.prototype.toString.call(result);
      return type === "[object Object]" || type === "[object Array]";
    } catch (err) {
      return false;
    }
  }
  log($v) {
    console.debug($v);
  }
  pluck(arr, key) {
    return arr.map((o) => o[key]);
  }
  arraySum(arr) {
    return arr.reduce((total, num) => {
      return Number.parseFloat(total) + Number.parseFloat(num);
    }, 0);
  }
  buildFormData(formData, data, parentKey) {
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        this.buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else {
      const value = data == null ? "" : data;

      formData.append(parentKey, value);
    }
  }
  jsonToFormData(data) {
    const formData = new FormData();

    this.buildFormData(formData, data);

    return formData;
  }
  rendGenerator(length = 8) {
    var timestamp = new Date();
    var _getRandomInt = function(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };
    var randomStr = function() {
      var ts = timestamp.toString();
      var parts = ts.split("").reverse();
      var id = "";

      for (var i = 0; i < length; ++i) {
        var index = _getRandomInt(0, parts.length - 1);
        id += parts[index];
      }
      id.replace(" ", "");
      return id;
    };
    return randomStr();
  }
  randomNumber(min = 111111, max = 999999) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }
  webUrl() {
    if (window.location.origin.includes("localhost"))
      return "http://localhost:8001";
    // return `https://quotes.rocketflood.com`;
    return window.location.origin;
  }
  attachmentBase() {
    return this.webUrl() + "/rocketflood.com/backend/public";
  }
  reportDownloadBase() {
    return this.webUrl() + "/rocketflood.com/backend/public";
  }
  baseUrl() {
    if (window.location.origin.includes("localhost"))
      return "http://localhost:8001/api";
    // return this.webUrl() + "/rocketflood.com/backend/public/api";
    return this.webUrl() + "/rocketflood.com/backend/public/api";
  }
  agentPortalApiEndpoint() {
    if (window.location.origin.includes("localhost"))
      return "http://localhost:8000/api";
    return "https://agent.rocketflood.com/agent.rocketflood.com/backend/public/api";
  }
  imageBase() {
    return this.webUrl() + "/rocketflood.com/backend/public";
  }
}

export default new Helper();
