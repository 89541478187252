import axios from "axios";
import store from "../store";
import helpers from "./helpers";

axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

const request = async (method, url, requestData, headers) => {
  try {
    const { data } = await axios({
      // baseURL: process.env.VUE_APP_API_SERVER_BASE_URL,
      method,
      url,
      data: requestData,
      headers: headers,
    });
    return Promise.resolve(data);
  } catch (err) {
    store.state.loading = false;
    console.info(error);
    if (!err) return Promise.reject(null);

    const error = {
      status: err.response.status,
      data: err.response.data,
    };
    return Promise.reject(error);
  }
};

export default {
  data() {
    return {
      offsetTop: window.pageYOffset || document.documentElement.scrollTop,
    };
  },
  mounted() {
    document.addEventListener(
      "scroll",
      () =>
        (this.offsetTop =
          window.pageYOffset || document.documentElement.scrollTop)
    );
  },
  computed: {
    companyPhoneNumber() {
      return "888-254-4563";
    },
    companyPhoneNumber2() {
      return "888-541-9847";
      return "561-404-4498";
    },
    baseUrl() {
      return helpers.baseUrl();
    },
    agentPortalApiEndpoint() {
      return helpers.agentPortalApiEndpoint();
    },
    baseDownloadUrl() {
      return helpers.reportDownloadBase();
    },
    apiKey() {
      return "rocket-flood-1qaz2wsx3edec-0okmnj1";
    },
    currentPageNumber: {
      get() {
        return this.$store.state.currentPageNumber;
      },
      set(value) {
        this.$store.state.currentPageNumber = value;
        this.$store.state.currentPageNumberHistory.push(value);
        return value;
      },
    },
  },
  methods: {
    getNumberFromStr(numberStr) {
      return parseInt(numberStr?.toString()?.replace(/[^\d.]/g, ""));
    },
    colSize(lgAndUp = 3, mdAndUp = 4, smAndUp = 6, smDown = 12) {
      let breakpoint = this.$vuetify.breakpoint;
      return breakpoint.lgAndUp
        ? lgAndUp
        : breakpoint.mdAndUp
        ? mdAndUp
        : breakpoint.smAndUp
        ? smAndUp
        : smDown;
    },
    async getGooglePlacePredictions(input) {
      var url = this.baseUrl + "/google-autocomplete-api/" + input;
      return await this.get(url);
      var cors = "https://cors-anywhere.herokuapp.com/";
      var url =
        cors +
        "https://maps.googleapis.com/maps/api/place/autocomplete/json?key=AIzaSyAx9V1bydwkNAYNR9QxLdQ9a9bIssU2Fo0&input=" +
        input;
      return this.get(url);
    },

    async getPropertyDetailsFromAttom(address1, address2) {
      // var url = this.baseUrl + `/attom-property-details-api/${address1}/${address2}`;
      // this.$store.state.loading = true;
      // return await axios.get(url, {
      //     headers
      // }).then(({
      //     data
      // }) => {
      //     this.$store.state.loading = false;
      //     return Promise.resolve(data)
      // }).catch(error => {
      //     this.$store.state.loading = false;
      //     console.log(error);
      // });

      var cors = "https://cors-anywhere.herokuapp.com/";
      var url = `${cors}https://api.gateway.attomdata.com/propertyapi/v1.0.0/property/basicprofile?address1=${address1}&address2=${address2}`;

      this.$store.state.loading = true;

      var headers = {
        "Content-Type": "application/json;charset=utf-8;multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        apiKey: "82e6928796da83d84fa1c31b65f6ea21",
      };

      return await axios
        .get(url, {
          headers,
        })
        .then(({ data }) => {
          this.$store.state.loading = false;
          return Promise.resolve(data);
        })
        .catch((error) => {
          this.$store.state.loading = false;
          // console.log(error);
        });
    },

    async getPropertyDetailsFromAttomApi(address) {
      var url = this.baseUrl + "/attom-property-details-api";
      return await this.post(
        url,
        {
          address,
        },
        true
      );
    },

    async enrollNow() {
      this.$store.state.enrollBtnIsDisabled = true;
      var url = this.baseUrl + "/enroll-now";
      var data = this.$store.state.responses;
      return this.post(url, data);
    },

    async sendQuoteEmail() {
      this.$store.state.emailBtnIsDisabled = true;
      var url = this.baseUrl + "/send-quote-email";
      var data = this.$store.state.responses;
      return this.post(url, data);
    },

    async downloadQuotePdf() {
      var url = this.baseUrl + "/download-quote";
      var data = this.$store.state.responses;
      return this.post(url, data);
    },

    async getApplicationQuote() {
      var url = this.baseUrl + "/get-application-quote";
      var data = {
        application: this.$store.state.data.application,
        starting_url: this.$store.state.starting_url,
      };
      return this.post(url, data);
    },
    date(date) {
      return date.toISOString().substring(0, 10);
      var year = date.getFullYear();
      var month =
        eval(1 + date.getMonth()) < 10
          ? 0 + "" + eval(1 + date.getMonth())
          : 1 + eval(date.getMonth());
      var day = date.getDate();
      return year + "-" + month + "-" + day;
    },
    formatedDate(date) {
      return date.toLocaleDateString();
      var year = date.getFullYear();
      var month =
        eval(1 + date.getMonth()) < 10
          ? 0 + "" + eval(1 + date.getMonth())
          : 1 + eval(date.getMonth());
      var day = date.getDate();
      return month + "/" + day + "/" + year;
    },
    back() {
      this.$router.go(-1);
    },
    pageBack() {
      if (this.$store.state.currentPageNumber > 0) {
        this.$store.state.currentPageNumberHistory.pop();
        this.$store.state.currentPageNumber = this.$store.state.currentPageNumberHistory[
          this.$store.state.currentPageNumberHistory.length - 1
        ];
      } else {
        this.back();
      }
    },
    pageNext() {
      if (this.currentPageNumber < 8)
        this.currentPageNumber = this.currentPageNumber + 1;
    },
    showMsg(type, title, disc) {
      this.$store.state.msg = {
        show: true,
        type: type,
        title: title,
        disc: disc,
      };
    },
    hideMsg() {
      this.$store.state.msg = {
        show: false,
        type: "",
        title: "",
        disc: "",
      };
    },
    async post(url, data = {}, loading = false) {
      var $this = this;
      if (loading) this.$store.state.loading = true;
      if (this.$store.state.session_key) {
        data["session_key"] = this.$store.state.session_key;
      }
      return await axios
        .post(url, data, {
          headers: {
            "Content-Type":
              "application/json;charset=utf-8;multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            apiKey: $this.apiKey,
          },
        })
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          this.$notify({
            group: "msg",
            title: "Error Message",
            text: "Internet issue or contact it team.",
            type: "error",
          });
          return null;
        })
        .finally(() => {
          if (loading) this.$store.state.loading = false;
        });
    },
    async get(url, headers = null, loading = false) {
      var $this = this;
      headers = {
        "Content-Type": "application/json;charset=utf-8;multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        apiKey: $this.apiKey,
      };

      if (loading) this.$store.state.loading = true;
      return await axios
        .get(url, {
          headers,
          crossdomain: true,
        })
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          this.$notify({
            group: "msg",
            title: "Error Message",
            text: "Internet issue or contact it team.",
            type: "error",
          });
          return null;
        })
        .finally(() => {
          if (loading) this.$store.state.loading = false;
        });
    },
  },
};
