<template>
	<v-card>
		<div>
			<v-spacer class="mt-12"></v-spacer>
		</div>
		<v-card-text class="pt-12">
			<h2 class="text-center">Licenses</h2>
		</v-card-text>
		<v-card-text class="container">
			<v-row no-gutters>
				<v-col
					:cols="colSize(4, 6, 6, 12)"
					v-for="(license, index) in licensees"
					:key="index"
				>
					<v-row no-gutters class="text-left pa-1 justify-center">
						<v-col :cols="colSize(5, 4, 6, 5)">
							<b> {{ index }}: </b>
						</v-col>
						<v-col :cols="colSize(5, 4, 6, 5)">
							{{ license }}
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
	import goTo from "vuetify/lib/services/goto";
	export default {
		data() {
			return {
				licensees: {
					Alabama: "3000328192",
					Arizona: "3000328167",
					Arkansas: "3000876200",
					California: "0M56807",
					Colorado: "626664",
					Connecticut: "2601011",
					Delaware: "3000339798",
					"District of Columbia": "3001090589",
					Florida: "L099428",
					Georgia: "200921",
					Hawaii: "475304",
					Idaho: "753320",
					Illinois: "3000339851",
					Indiana: "3498782",
					Iowa: "3000907335",
					Kansas: "821276665-000",
					Louisiana: "861629",
					Maine: "AGN355156",
					Maryland: "3000328203",
					Massachusetts: "2079182",
					Michigan: "116384",
					Minnesota: "40660741",
					Mississippi: "15033842",
					Missouri: "3000723530",
					Montana: "3000907253",
					Nebraska: "3000876134",
					Nevada: "3497639",
					"New Hampshire": "2453853",
					"New Jersey": "1673522",
					"New Mexico": "3000719593",
					"New York - Agency": "PC-1525526",
					"New York - Broker": "BR-1525526",
					"North Carolina": "1000583473",
					"North Dakota": "3000907233",
					Ohio: "1197777",
					Oklahoma: "3000714966",
					Oregon: "3000890794",
					Pennsylvania: "856107",
					"Rhode Island": "3000714933",
					"South Carolina": "1913341825",
					"South Dakota": "10027362",
					Tennessee: "2404424",
					Texas: "2278731",
					Utah: "753260",
					Vermont: "3557918",
					Virginia: "144056",
					Washington: "984969",
					"West Virginia": "3000715765",
					Wisconsin: "3000714939",
					Wyoming: "438324"
				}
			};
		},
		head: {
			title: function() {
				return {
					inner: "Licenses"
				};
			}
		},
		watch: {},
		mounted() {
			goTo(0);
		},
		methods: {}
	};
</script>
