import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import MainScriptsLoader from "@/views/layouts/main-scripts-loader";
import MainLayout from "@/views/layouts/main";
import QuoteLayout from "@/views/layouts/getAQuote";
import Home from "@/views/pages/Home";
import ThankYou from "@/views/pages/ThankYou";
import Faq from "@/views/pages/Faq";
import PrivacyPolicy from "@/views/pages/PrivacyPolicy";
import Licenses from "@/views/pages/Licenses";
import TermsOfUse from "@/views/pages/TermsOfUse";
import AboutUs from "@/views/pages/AboutUs";
import Address from "@/views/pages/Address";
import InsuranceForm from "@/views/pages/InsuranceForm";
import AuthLayout from "@/views/layouts/auth";
import CmsLayout from "@/views/layouts/cms";
import Login from "@/views/pages/auth/Login";
import Logout from "@/views/pages/auth/Logout";
import Dashboard from "@/views/pages/dashboard/index";
import NoRoute404 from "@/views/errors/404.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/thankyou",
    name: "thankyou",
    component: ThankYou,
  },
  {
    path: "",
    component: MainScriptsLoader,
    children: [
      {
        path: "",
        component: MainLayout,
        props: {
          headerSmall: false,
        },
        children: [
          {
            path: "/",
            name: "home",
            component: Home,
          },
        ],
      },
      {
        path: "",
        component: MainLayout,
        props: {
          headerSmall: true,
        },
        children: [
          {
            path: "/faq",
            name: "faq",
            component: Faq,
          },
          {
            path: "/about-us",
            name: "about-us",
            component: AboutUs,
          },
          {
            path: "/privacy-policy",
            name: "privacy-policy",
            component: PrivacyPolicy,
          },
          {
            path: "/terms-of-use",
            name: "terms-of-use",
            component: TermsOfUse,
          },
          {
            path: "/licenses",
            name: "licenses",
            component: Licenses,
          },
        ],
      },
      {
        path: "",
        component: QuoteLayout,
        children: [
          {
            path: "/get-a-quote/address",
            name: "getAQuoteAddress",
            component: Address,
          },
        ],
      },
      {
        path: "/insurance-form",
        name: "insurance-form",
        component: InsuranceForm,
      },
    ],
  },
  {
    path: "",
    component: AuthLayout,
    children: [
      {
        name: "login",
        path: "login",
        component: Login,
        meta: {
          forGuest: true,
        },
      },
      {
        name: "logout",
        path: "logout",
        component: Logout,
        meta: {
          forGuest: false,
        },
      },
    ],
  },
  {
    path: "/dashboard",
    component: () => import("@/views/dashboard/Index"),
    meta: {
      requireAuth: true,
    },
    children: [
      // Dashboard
      {
        name: "dashboard-home",
        path: "",
        component: () => import("@/views/dashboard/Dashboard"),
      },
      // Pages
      {
        name: "user-profile",
        path: "user/profile",
        component: () => import("@/views/dashboard/pages/UserProfile"),
      },
      {
        name: "Notifications",
        path: "components/notifications",
        component: () => import("@/views/dashboard/component/Notifications"),
      },
      {
        name: "Icons",
        path: "components/icons",
        component: () => import("@/views/dashboard/component/Icons"),
      },
      {
        name: "Typography",
        path: "components/typography",
        component: () => import("@/views/dashboard/component/Typography"),
      },
      // Tables
      {
        name: "Regular Tables",
        path: "tables/regular-tables",
        component: () => import("@/views/dashboard/tables/RegularTables"),
      },
      // Maps
      {
        name: "Google Maps",
        path: "maps/google-maps",
        component: () => import("@/views/dashboard/maps/GoogleMaps"),
      },
      // Upgrade
      {
        name: "Upgrade",
        path: "upgrade",
        component: () => import("@/views/dashboard/Upgrade"),
      },
    ],
  },
  {
    path: "*",
    name: "404",
    component: NoRoute404,
    meta: {
      title: "Page Not Found 404",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requireAuth = to.matched.some((x) => x.meta.requireAuth);
  const forGuest = to.matched.some((x) => x.meta.forGuest);

  if (store.state.starting_url == null) {
    store.commit("setStartingUrl", window.location.href);
  }

  if (forGuest && store.state.authenticated) {
    console.log("Redirected");
    next({
      name: "dashboard-home",
    });
  } else if (requireAuth && !store.state.authenticated) {
    console.log("Redirected");
    next({
      name: "login",
    });
  } else {
    next();
  }
});

export default router;
