<template>
	<v-container
		class="d-flex flex-column align-center justify-center fill-height"
	>
		<a
			style="cursor: pointer;width:100%;max-width:400px"
			class="mb-12"
			href="/"
		>
			<img
				src="@/assets/images/5d9a33664aefbeb0b6970a93_RocketFlood-Logo.svg"
				alt
			/>
		</a>

		<div class="" style="max-width:600px;">
			<h1>Thank You!</h1>

			<p>
				Rocket Flood automation is now quoting you with over 20 flood carriers!
				We will email & text you the best flood quote within 10 minutes.
			</p>
			<p>
				For immediate pricing please call us at
				<a href="tel:888-871-2237">888-871-2237</a>
			</p>
			<p>
				IF YOU HAVE AN ELEVATION CERTIFICATE PLEASE EMAIL IT TO
				<a href="mailto:quotes@rocketflood.com">quotes@rocketflood.com</a> FOR
				THE LOWEST POSSIBLE RATE
			</p>
		</div>
	</v-container>
</template>

<script>
	import goTo from "vuetify/lib/services/goto";
	export default {
		components: {},
		data() {
			return {};
		},
		head: {
			title: function() {
				return {
					inner: "Thank You!"
				};
			},
			meta: [{ name: "description", content: "Thank you", id: "desc" }]
		},
		props: {},
		watch: {},
		mounted() {
			goTo(0);
		},
		methods: {}
	};
</script>


<style lang="scss">
</style>

