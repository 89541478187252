<template>
  <div class="testimonialContainer" :style="variableHight">
    <VueSlickCarousel
      style="width: 100%; height: 100%"
      v-bind="settings"
      @afterChange="height()"
    >
      <template v-if="['sm', 'xs'].includes($vuetify.breakpoint.name)">
        <div style="width: 100%; height: 100%" class="w-slide">
          <div class="slider-bg">
            <div class="slider-flex-block">
              <div class="stars"></div>
              <p style="min-height: 100px" class="review-text">
                Rocket Flood shopped the market place for me quickly and within
                2 hours they saved me 35% on my flood insurance. They made the
                process quick and easy, I highly recommend working with them!
              </p>
              <p class="review-name">
                Cheryl J.
                <br />New Jersey
              </p>
            </div>
          </div>
        </div>
        <div style="width: 100%; height: 100%" class="w-slide">
          <div class="slider-bg">
            <div class="slider-flex-block">
              <div class="stars"></div>
              <p style="min-height: 100px" class="review-text">
                My premium was increased through FEMA so I decided to shop
                around and see what my options were. I found Rocket Flood online
                and was amazed when my agent Derek was able to save me $1200 off
                of my renewal premium. I definitely feel lucky that I found
                them.
              </p>
              <p class="review-name">
                Seth K.
                <br />Florida
              </p>
            </div>
          </div>
        </div>
        <div style="width: 100%; height: 100%" class="w-slide">
          <div class="slider-bg">
            <div class="slider-flex-block">
              <div class="stars"></div>
              <p style="min-height: 100px" class="review-text">
                I own 4 vacation rental properties and I am forced by my
                mortgage to carry flood insurance. The flood insurance premiums
                really eat into my profits, so every year I look to see if I can
                get a better deal. This year my realtor recommended I reach out
                to the Rocket Flood team and I am seriously happy that I did.
                Turns out I was paying way too much through FEMA’s National
                flood insurance program. Rocket was able to lower my premiums
                and provide “loss of rents” coverage which is valuable to me as
                a landlord. Do yourself a favor and call Rocket Flood today.
              </p>
              <p class="review-name">
                Chase M.
                <br />Virginia Beach
              </p>
            </div>
          </div>
        </div>
        <div style="width: 100%; height: 100%" class="w-slide">
          <div class="slider-bg">
            <div class="slider-flex-block">
              <div class="stars"></div>
              <p style="min-height: 100px" class="review-text">
                My house was recently re-mapped into a flood zone and I received
                a letter from my mortgage company that I was now required to
                carry flood insurance. I searched around and started getting
                quotes from multiple agents. Rocket Flood was $1100 lower than
                any other agent, they made the process simple and sent the
                necessary paperwork directly to my mortgage company. Thanks
                Rocket Flood!
              </p>
              <p class="review-name">
                Tracy S.
                <br />South Carolina
              </p>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div style="width: 100%; height: 100%" class="w-slide">
          <div class="slider-bg">
            <div class="slider-flex-block">
              <div class="stars"></div>
              <p style="min-height: 100px" class="review-text">
                Rocket Flood shopped the market place for me quickly and within
                2 hours they saved me 35% on my flood insurance. They made the
                process quick and easy, I highly recommend working with them!
              </p>
              <p class="review-name">
                Cheryl J.
                <br />New Jersey
              </p>
            </div>
            <div class="slider-flex-block">
              <div class="stars"></div>
              <p style="min-height: 100px" class="review-text">
                My premium was increased through FEMA so I decided to shop
                around and see what my options were. I found Rocket Flood online
                and was amazed when my agent Derek was able to save me $1200 off
                of my renewal premium. I definitely feel lucky that I found
                them.
              </p>
              <p class="review-name">
                Seth K.
                <br />Florida
              </p>
            </div>
          </div>
        </div>
        <div style="width: 100%; height: 100%" class="w-slide">
          <div class="slider-bg">
            <div class="slider-flex-block">
              <div class="stars"></div>
              <p style="min-height: 100px" class="review-text">
                I own 4 vacation rental properties and I am forced by my
                mortgage to carry flood insurance. The flood insurance premiums
                really eat into my profits, so every year I look to see if I can
                get a better deal. This year my realtor recommended I reach out
                to the Rocket Flood team and I am seriously happy that I did.
                Turns out I was paying way too much through FEMA’s National
                flood insurance program. Rocket was able to lower my premiums
                and provide “loss of rents” coverage which is valuable to me as
                a landlord. Do yourself a favor and call Rocket Flood today.
              </p>
              <p class="review-name">
                Chase M.
                <br />Virginia Beach
              </p>
            </div>
            <div class="slider-flex-block">
              <div class="stars"></div>
              <p style="min-height: 100px" class="review-text">
                My house was recently re-mapped into a flood zone and I received
                a letter from my mortgage company that I was now required to
                carry flood insurance. I searched around and started getting
                quotes from multiple agents. Rocket Flood was $1100 lower than
                any other agent, they made the process simple and sent the
                necessary paperwork directly to my mortgage company. Thanks
                Rocket Flood!
              </p>
              <p class="review-name">
                Tracy S.
                <br />South Carolina
              </p>
            </div>
          </div>
        </div>
      </template>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  components: { VueSlickCarousel },
  data() {
    return {
      variableHight: "height: 400px !important;",
    };
  },
  computed: {
    settings() {
      // if (["sm", "xs"].includes(this.$vuetify.breakpoint.name))
      return {
        arrows: true,
        edgeFriction: 0.35,
        dots: false,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        touchThreshold: 10,
        autoplay: true,
        autoplaySpeed: 4000,
      };
    },
  },
  methods: {
    height() {
      let height = document.querySelector(".slick-current .slider-bg")
        ? document.querySelector(".slick-current .slider-bg").offsetHeight
        : 400;
      this.variableHight = `height: ${height}px !important;`;
      return this.variableHight;
    },
  },
};
</script>

<style lang="scss">
.testimonialContainer {
  height: 340px;
  width: 90%;
}
@media (max-width: 820px) {
  .testimonialContainer {
    height: auto !important;
  }
}
@media (max-width: 767px) {
  .testimonialContainer {
    height: auto !important;
  }
}
.slick-arrow {
  height: 30px !important;
  width: 30px !important;
  border-radius: 50% !important;
  // color: #ffffff !important;
  // background-color: #4a4a4a !important;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 30px;
  line-height: 1;
  opacity: 0.75;
  color: #4a4a4a !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>