<template>
  <div v-if="$store.state.msg.show">
    <div
      class="bootbox modal fade show"
      tabindex="-1"
      role="dialog"
      aria-modal="true"
      style="display: block; padding-left: 17px;"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-html="$store.state.msg.title"></h5>
            <button
              type="button"
              class="bootbox-close-button close"
              aria-hidden="true"
              @click="hideMsg"
            >×</button>
          </div>
          <div class="modal-body">
            <div class="bootbox-body" v-html="$store.state.msg.disc"></div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger bootbox-cancel" @click="hideMsg">Close</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
export default {
  props: {},
  methods: {}
};
</script>

<style scoped lang="scss">
.modal-backdrop {
  background: rgba(126, 126, 126, 0.8) !important;
}
</style>
