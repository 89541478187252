<template>
	<v-card>
		<div>
			<v-spacer class="mt-12"></v-spacer>
		</div>
		<v-card-text class="pt-12">
			<h2 class="text-center">Rocket Flood Privacy Policy</h2>
		</v-card-text>
		<v-card-text class="container">
			<div class="white">
				<br />
				Rocket Flood Website Privacy Policy – Your Privacy Rights
				<br />
				Effective date September 24th, 2021
				<br />
				This Privacy Policy applies to the sites and apps where it appears.
				<br />
				This Policy describes how we treat personal information on the websites
				and apps where it is located. Your use of this website indicates that
				you agree to our collection, disclosure, use, of your information as
				described in this Privacy Policy. This includes Rocket Flood’s flood,
				home, auto and life insurance services. It also applies to our mobile
				sites and apps, including our Rocket Flood app.
				<br />
				We collect information from and about you.
				<br />
				We collect contact information. For example, we might collect your name
				and street address if you register on our site or in our app. We might
				also collect your phone number or email address. If you choose to invite
				friends to download our app or use our site, we will collect their email
				address.
				<br />
				We collect information you submit or post. We collect the information
				you post in a public space on our site. We also collect information when
				you contact us.
				<br />
				We collect demographic information. We may collect information like your
				gender, age, date of birth and marital status. We may also collect your
				zip code. We might collect this when you contact us or enter a
				promotion.
				<br />
				We collect additional information depending upon which services you are
				interested in. For example, if you request flood insurance, we will
				collect additional information about your property’s flood risk and
				property details. For home insurance, we will collect additional
				information including information about your homeowner status and the
				property where you live. If you request information about car insurance,
				we will collect additional information including information about your
				vehicle. If you request information about life insurance, we will
				collect additional information including information about your
				occupation, physical activity level, veteran status, tobacco use, DUI
				history, height and weight, and medical history. We also collect
				information about your current insurance coverage.
				<br />
				We collect usage and device/location information. If you use our
				website, we may collect information about the browser you’re using. We
				might look at what site you came from, or what sites you visit when you
				leave us. On our app, we may collect information about your location,
				including saved locations. This may include your precise location. We
				may collect this using different types of technologies, including GPS
				and Wi-Fi. If you use our mobile app, we may also access your device’s
				location information in the background. We might also collect device
				identifiers. You may also elect to provide us with access to your device
				contact lists or other social media contact lists.
				<br />
				We collect information in different ways.
				<br />
				We collect information directly from you. For example, we collect
				information from you if you use our website, request insurance
				information or register for our app. We also collect information if you
				contact us. See the section below entitled, “We may share information
				with third parties” for more information.
				<br />
				We collect information from you passively. We use tracking tools like
				browser cookies and web beacons. We do this on our websites and in
				emails that we send to you. We collect information about users over time
				when you use this website. We may have third parties collect personal
				information this way. Learn more about these tools and how you can
				control them, here.
				<br />
				We also collect information from our mobile apps. This includes
				information about your device, including your device and app ID. If you
				have enabled location services for our app, we collect this information
				in the background, even when you do not have our app open on your phone.
				<br />
				We get information about you from third parties. For example, our
				business partners may give us information about you, including contact,
				demographic and other personal information. These may include insurance
				providers, credit reporting agencies and other data companies. Social
				media platforms may also give us information about you.
				<br />
				We combine information. For example, we may combine information we get
				from a third party with information we already have. We may also combine
				information collected from you on our various platforms. We may also
				obtain information about you based on other websites you have visited.
				<br />
				We use information as disclosed and described here.
				<br />
				We use information to respond to your requests or questions. For
				example, we and our business partners use your information to select and
				provide you with information about insurance products you may be
				interested in. We may also use your information to respond to your
				customer feedback. We may use your information to notify you if you win
				a contest or sweepstakes.
				<br />
				We use information to improve our products and services. We may use your
				information to make our website and products better. We might use your
				information to customize your experience with us.
				<br />
				We use information for security purposes. We may use your information to
				protect our company and our customers. We also use information to
				protect our websites and apps.
				<br />
				We use information for marketing purposes. For example, we might provide
				you with information, special offers, and advertisements about products.
				This information may be from or on behalf of insurance providers or
				other third parties. We might tell you about new features or updates. We
				might also use your information to serve you ads about products and
				offers. By submitting your email address and/or phone number to us, you
				authorize us to use that email address and phone number to contact you
				periodically, including by the use of SMS, MMS, an auto-dialer and
				pre-recorded messages, concerning (i) your quote requests, (ii) about
				the website, app or our services and (iii) information or offers that we
				feel may be of interest to you. To learn about your choices for these
				communications, read the choices. We may also allow our partners to
				provide you with information about new products and special offers,
				including offers for insurance products and services.
				<br />
				We use information to communicate with you. For example, we will
				communicate with you about your account or our relationship. We may
				contact you about your feedback. We might also contact you about this
				Policy or our website Terms.
				<br />
				We may also use push notifications through your browser and on our
				mobile apps. We will send you push notifications about insurance related
				offers and services. We may also send you push notifications for other
				reasons like friend requests, prompts to view your trips, or product
				updates.
				<br />
				We use information as otherwise permitted by law or as we may notify
				you.
				<br />
				We may share information with third parties.
				<br />
				We will share information with third parties who perform services on our
				behalf. For example, we share information with vendors who send emails
				for us. We may also share information with companies that operate our
				websites, app or run a promotion.
				<br />
				We will share information with our business partners and other third
				parties. These partners may send you information about products and
				services by telephone, text, fax, mail or email. If you use our website
				or app we will share your information, including name, address, email,
				telephone number, and date of birth, which you provided to us or that we
				obtained from third-party sources, with insurance companies or other
				third parties that may provide it to insurance companies. Insurance
				companies that receive your information may use it to assist in
				providing you offers for insurance. You further acknowledge and agree
				that each insurance company that receives your quote request from this
				website or from our affiliates may confirm your information through the
				use of a consumer reporting agency to determine eligibility and accurate
				rates.
				<br />
				We will share information if we think we have to in order to comply with
				the law or to protect ourselves. For example, we will share information
				to respond to a court order or subpoena. This may include information
				that is discoverable in connection with accident investigations,
				litigation or both. We may share it if a government agency or
				investigatory body requests. We might share information when we are
				investigating potential fraud.
				<br />
				We may share information with any successor to all or part of our
				business. For example, if part of our business was sold we may give our
				customer list as part of that transaction.
				<br />
				You have certain choices about how we use your information.
				<br />
				You can opt out of receiving our marketing emails. To stop receiving our
				promotional emails, email customer support at quotes@rocketflood.com or
				follow the instructions in any promotional message you get from us. Even
				if you opt out of getting marketing messages, we will still send you
				transactional messages. These include responses to your questions. If
				you opt-out of receiving marketing communications from our business
				partners with whom we have shared your information, you will still
				receive marketing communications from us and any other business partners
				with whom your information was shared.
				<br />
				You can request that we stop sharing information with third parties for
				their marketing purposes. To opt out of having us share your information
				with third parties for their promotional purposes, email us at
				legal@rocketflood.com
				<br />
				You can control cookies and tracking tools. To learn how to manage how
				we – and our vendors – use cookies and other tracking tools, and to read
				our Do Not Track policy, please click here.
				<br />
				You can control tools on your mobile devices. For example, you can turn
				off the GPS locator or push notifications on your phone through your
				phone’s settings. YOUR CALIFORNIA PRIVACY RIGHTS
				<br />
				Consumers residing in California have certain rights with respect to
				their personal information under the California Consumer Privacy Act or
				(“CCPA”) (California Civil Code Section 1798.100 et seq.) and the “Shine
				the Light” Law (California Civil Code Section 1798.83). If you are a
				California resident, this section applies to you.
				<br />
				California Consumer Privacy Act
				<br />
				Additional Disclosures Related to the Collection, Use, Disclosure and
				Sale of Personal Information
				<br />
				Collection and Use of Personal Information: In the Preceding 12 months,
				we have collected the following categories of personal information:
				identifiers, categories of personal information described in subdivision
				(e) of Section 1798.80 Including financial information, commercial
				information, biometric information, internet or other electronic network
				activity information, geolocation data, professional or
				employment-related information, education information, and inferences
				draw from any of the information identified.
				<br />
				Disclosure of Personal Information: We may share your personal
				information with third parties as described in the "We may share
				information with third parties" section above. In the preceding 12
				months, we may have disclosed the following categories of personal
				information for business or commercial purposes: identifiers, categories
				of personal information described in subdivision (e) of Section 1798.80
				including financial information, commercial information, biometric
				information, internet or other electronic network activity information,
				geolocation data, professional or employment-related information,
				education information, and inferences draw from any of the information
				identified.
				<br />
				Sale of Personal Information: California law requires that we provide
				transparency about personal information we "sell," which for the
				purposes of the CCPA, means scenarios in which personal information is
				shared with third parties in exchange for valuable consideration. In the
				preceding 12 months, we have "sold" the following categories of personal
				information: identifiers, categories of personal information described
				in subdivision (e) of Section 1798.80 including financial information,
				commercial information, biometric information, internet or other
				electronic network activity information, geolocation data, professional
				or employment-related information, education information, and inferences
				draw from any of the information identified. California consumers above
				the age of 16 have the right to opt out of these sales at any time. We
				do not knowingly sell personal information about consumers under the age
				of 16. Please visit our "Do Not Sell My Info" webpage to learn more
				about how we sell information and to make an opt-out request.
				<br />
				Your Consumer Rights
				<br />
				If you are a California resident, you have the right to (1) request more
				information about the categories and specific pieces of personal
				information we have collected, sold and disclosed for a business purpose
				in the last 12 months, (2) request deletion of your personal
				information, (3) opt out of the sale of your personal information, if
				applicable, and (4) be free from discrimination for exercising your
				rights under the CCPA. You may make these requests by submitting a
				request here or by calling 888-923-5663. To opt out of sales, you can
				visit our "Do Not Sell My Info" webpage. We will verify your request by
				confirming at least two pieces of personal information, including
				contact information, you provided in submitting your form. To designate
				an authorized agent to exercise these rights on your behalf, send an
				email with your request to legal@rocketflood.com
				<br />
				We store information in the United States.
				<br />
				Information we maintain may be stored within the United States. If you
				live outside of the United States, you understand and agree that you are
				transferring your information to the United States. Our platforms are
				subject to U.S. laws, which may not afford the same level of protection
				as those in your country.
				<br />
				We may link to other sites or apps from our websites, platforms, or
				share information with third parties we don’t control. If you click on a
				third party link, you will be taken to another website or platform we do
				not control. This policy does not apply to the privacy practices of that
				website or platform. Read other companies’ privacy policies carefully.
				We are not responsible for these third parties.
				<br />
				Our site may also serve third party content that contains their own
				cookies or tracking technologies. To learn more, click here. We do not
				control the use of those technologies.
				<br />
				Feel free to contact us if you have more questions.
				<br />
				If you have any questions about this Policy, please email us at
				legal@rocketflood.com.
				<br />
				You can also write to us or call at:
				<br />
				Rocket Flood
				<br />
				2005 Vista Parkway, Suite 200
				<br />
				West Palm Beach, FL 33411
				<br />
				Phone: (888) 923-5663
				<br />
				We may update this Policy.
				<br />
				From time to time we may change our privacy policies. We will notify you
				by email or by means of a notice on the website or app of any material
				changes to our Policy as required by law. We will also post an updated
				copy on our website. Please check our site periodically for updates. ©
				2017 Rocket MGA LLC DBA Rocket Flood. All rights reserved.
				<br />
				<br />
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
	import goTo from "vuetify/lib/services/goto";
	export default {
		data() {
			return {};
		},
		head: {
			title: function() {
				return {
					inner: "Privacy Policy"
				};
			}
		},
		watch: {},
		mounted() {
			goTo(0);
		},
		methods: {}
	};
</script>
