<template>
	<div>
		<div class="main-body-section pa-0">
			<div class="sperator" style="height: 70px"></div>
			<div class="main-body-flex-block" style="padding-bottom: 0">
				<h2 class="left">
					<strong>Frequently Asked Questions</strong>
				</h2>
				<p class="left">Flood Insurance 101</p>
				<div class="flex-wrap">
					<div class="faq-col-left" style="min-height: 400px">
						<div
							class="faq-main-wrap hidden faq-1"
							@click="toggleFaqComment('faq-1')"
						>
							<div class="faq-block">
								<div class="faq-icon-wrap">
									<div class="plus"></div>
								</div>
								<h3 class="faq-header noselect">
									Doesn’t my Homeowners Insurance policy cover flooding?
								</h3>
							</div>
							<div class="faq-comment-wrap">
								<p class="faq-paragraph">
									The coverage offered in a Flood Insurance policy may sound
									like coverage you already have within your Homeowners policy,
									but that is not the case. Flood coverage is different than
									your Homeowners insurance because most Homeowners policies
									specifically exclude water damage that results from rising
									water. For insurance lingo, “rising” water distinguishes flood
									damage from water damage.
									<br />
								</p>
							</div>
						</div>
						<div
							class="faq-main-wrap hidden faq-2"
							@click="toggleFaqComment('faq-2')"
						>
							<div class="faq-block">
								<div class="faq-icon-wrap">
									<div class="plus"></div>
								</div>
								<h3 class="faq-header noselect">
									What is the average paid flood claim?
								</h3>
							</div>
							<div class="faq-comment-wrap">
								<p class="faq-paragraph">
									During the last five years, the average flood claim was
									$46,000.
									<br />
								</p>
							</div>
						</div>
						<div
							class="faq-main-wrap hidden faq-3"
							@click="toggleFaqComment('faq-3')"
						>
							<div class="faq-block">
								<div class="faq-icon-wrap">
									<div class="plus"></div>
								</div>
								<h3 class="faq-header noselect">
									What types of events are considered “flood” events?
								</h3>
							</div>
							<div class="faq-comment-wrap">
								<p class="faq-paragraph no-pad">
									There are four primary causes of floods:
									<br />
								</p>
								<ul class="faq-list">
									<li class="faqs-list-item">
										River or coastal flooding, such as spring runoff, raising
										river levels or hurricane driven rains and tidal surges.
									</li>
									<li class="faqs-list-item">
										Rapid runoff or buildup of water, such as torrential rains
										in low lying areas.
									</li>
									<li class="faqs-list-item">
										Mudflow in inundated, normally dry areas.
									</li>
									<li class="faqs-list-item">
										Collapse of water retention structures such as levees or
										dams, or erosion of coastal or lakeside seawalls and
										shoreline. Flooding can occur anywhere, whether along the
										shore, inland or at altitude. Major events occur in every
										state in the US.
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="faq-col-right" style="min-height: 400px">
						<div
							class="faq-main-wrap hidden faq-4"
							@click="toggleFaqComment('faq-4')"
						>
							<div class="faq-block">
								<div class="faq-icon-wrap">
									<div class="plus"></div>
								</div>
								<h3 class="faq-header noselect">
									I don’t live near water. How do I know if I need flood
									insurance?
								</h3>
							</div>
							<div class="faq-comment-wrap">
								<p class="faq-paragraph">
									If your property is identified as “high risk” (A and V flood
									zones), most lenders require you to have flood insurance.
									Regardless, no one is without potential flood risk. Wherever
									it rains, it can flood. Since 25% of all flood claims are
									actually located outside of “high risk” A and V zones, only
									you can answer this question for your family. You might
									consider the cost of insurance vs. the average paid claim to
									decide if you can afford to cover your own loss.
									<br />
								</p>
							</div>
						</div>
						<div
							class="faq-main-wrap hidden faq-5"
							@click="toggleFaqComment('faq-5')"
						>
							<div class="faq-block">
								<div class="faq-icon-wrap">
									<div class="plus"></div>
								</div>
								<h3 class="faq-header noselect">
									What is the average flood premium?
								</h3>
							</div>
							<div class="faq-comment-wrap">
								<p class="faq-paragraph">
									The average cost of flood insurance is $700 per year. But,
									because rates are based on many variables, including flood
									zone, coverage amounts and the elevation of your home, your
									premium will likely be far less if you are in a moderate to
									low risk zone. A or V zones (high risk) are wide-ranging, but
									they average around $1,500 per year.
									<br />
								</p>
							</div>
						</div>
						<div
							class="faq-main-wrap hidden faq-6"
							@click="toggleFaqComment('faq-6')"
						>
							<div class="faq-block">
								<div class="faq-icon-wrap">
									<div class="plus"></div>
								</div>
								<h3 class="faq-header noselect">
									Won’t the Federal Government just bail me out if I don’t have
									flood insurance?
								</h3>
							</div>
							<div class="faq-comment-wrap">
								<p class="faq-paragraph">
									If your home is within a declared disaster area, you may apply
									to borrow up to $200,000 to repair or replace your primary
									home to its pre-disaster condition. It’s actually a loan that
									must be paid back with interest and not always available. You
									can’t use the loan to upgrade or add on to the home, unless
									required by building authority or code. Second homes or
									vacation properties are not eligible to apply for this federal
									loan program.
									<br />
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import goTo from "vuetify/lib/services/goto";
	export default {
		data() {
			return {};
		},
		head: {
			title: function() {
				return {
					inner: "FAQs"
				};
			},
			meta: [{ name: "description", content: "RocketFlood", id: "desc" }]
		},
		watch: {},
		mounted() {
			goTo(0);
		},
		methods: {
			toggleFaqComment(refClass) {
				let element = document.querySelector(`.${refClass}`);
				if (element.classList.contains("showed")) {
					element.classList.remove("showed");
					element.classList.add("hidden");
				} else {
					element.classList.remove("hidden");
					element.classList.add("showed");
				}
			}
		}
	};
</script>


<style lang="scss">
	.faq-main-wrap.hidden {
		.faq-paragraph {
			display: none;
		}
		.faq-comment-wrap {
			display: none;
		}
	}
	.faq-main-wrap.showed {
		.faq-paragraph {
			display: block;
		}
		.faq-comment-wrap {
			display: block;
		}
	}
</style>