<template>
  <v-app>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <router-view />
      </v-layout>
    </v-container>
    <notifications position="bottom center" group="msg" />
  </v-app>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {},
  watch: {},
  created() {},
  methods: {},
};
</script>
