<template>
	<v-app>
		<!-- Create new button -->
		<v-container>
			<v-btn
				block
				color="secondary"
				@click="createInsuranceRequestDialog = true"
				>Create New Insurance Request</v-btn
			>
		</v-container>

		<!-- all data table -->
		<v-container>
			<v-data-table
				style="width: 100%"
				:headers="table_headers"
				:items="allInsuranceRequests"
				:items-per-page="5"
				class="elevation-1"
			>
				<template v-slot:item.actions="{ item }">
					<v-btn @click="deleteInsuranceRequest(item)" icon color="red">
						<v-icon>mdi-delete</v-icon>
					</v-btn>
					<a
						:href="`${baseUrl}/insurance-request/${item.id}/payload`"
						target="_blank"
						rel="noopener noreferrer"
					>
						<v-btn icon color="green">
							<v-icon>mdi-link</v-icon>
						</v-btn>
					</a>
					<a
						:href="`${baseUrl}/insurance-request/${item.id}/set-response`"
						target="_blank"
						rel="noopener noreferrer"
					>
						<v-btn icon color="orange">
							<v-icon>mdi-link</v-icon>
						</v-btn>
					</a>
				</template>
			</v-data-table>
		</v-container>

		<!-- create new form dialog -->
		<v-dialog
			v-model="createInsuranceRequestDialog"
			max-width="700px"
			width="100%"
			v-if="createInsuranceRequestDialog"
		>
			<v-card color="white" style="width: 100%">
				<v-card-title style="background-color: #80808029"
					>Create New Insurance Request
					<v-spacer></v-spacer>
					<v-btn icon color="red" @click="createInsuranceRequestDialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text>
					<v-form-base
						:col="{ cols: 12 }"
						:model="form_data"
						:schema="form_structure"
						@input="handleInput"
					/>
				</v-card-text>
				<v-card-actions>
					<v-btn block color="secondary" @click="createInsuranceRequest()">
						Store
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-app>
</template>

<script>
	import VFormBase from "vuetify-form-base";
	import { mapState } from "vuex";
	import axios from "axios";

	export default {
		components: { VFormBase },
		data: () => {
			return {
				allInsuranceRequests: [],
				createInsuranceRequestDialog: false,
				table_headers: [
					{
						text: "First name",
						sortable: false,
						value: "payload.first_name"
					},
					{
						text: "Last name",
						sortable: false,
						value: "payload.last_name"
					},
					{
						text: "Building use",
						sortable: false,
						value: "payload.building_use"
					},
					{
						text: "Building limit",
						sortable: false,
						value: "payload.building_limit"
					},
					{
						text: "Contents limit",
						sortable: false,
						value: "payload.contents_limit"
					},
					{
						text: "Deductible",
						sortable: false,
						value: "payload.deductible"
					},
					{
						text: "Effective date",
						sortable: false,
						value: "payload.effective_date"
					},
					{
						text: "Address",
						sortable: false,
						value: "payload.address"
					},
					{
						text: "Actions",
						sortable: false,
						value: "actions"
					}
				],
				form_data: {
					first_name: "Jhone",
					last_name: "Adams",
					building_use: "Primary",
					building_limit: 250000,
					contents_limit: 100000,
					deductible: 5000,
					effective_date: "15/03/2020",
					address: "N 005, Prateek Wisteria, Noida, Florida, 70431"
				}
			};
		},
		computed: {
			form_structure() {
				return {
					first_name: {
						type: "text",
						label: "First Name",
						clearable: true,
						col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 }
					},
					last_name: {
						type: "text",
						label: "Last Name",
						clearable: true,
						col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 }
					},
					building_use: {
						type: "text",
						label: "Building Use",
						clearable: true,
						col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 }
					},
					building_limit: {
						type: "text",
						label: "Building limit",
						clearable: true,
						col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 }
					},
					contents_limit: {
						type: "text",
						label: "Contents limit",
						clearable: true,
						col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 }
					},
					deductible: {
						type: "text",
						label: "Deductible",
						clearable: true,
						col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 }
					},
					effective_date: {
						type: "text",
						label: "Effective date",
						clearable: true,
						col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 }
					},
					address: {
						type: "textarea",
						label: "Address",
						clearable: true,
						col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 }
					}
				};
			}
		},
		watch: {},
		mounted() {
			this.getAllInsuranceRequests();
		},
		methods: {
			getAllInsuranceRequests() {
				var $this = this;
				var url = this.baseUrl + "/insurance-request";
				return this.get(url).then(data => {
					data.data.forEach(data => {
						$this.allInsuranceRequests.push(data);
					});
				});
			},
			createInsuranceRequest() {
				var $this = this;
				var url = this.baseUrl + "/insurance-request";
				var data = { payload: JSON.stringify(this.form_data) };
				return this.post(url, data).then(data => {
					data.data.forEach(data => {
						$this.allInsuranceRequests.push(data);
					});
					$this.createInsuranceRequestDialog = false;
				});
			},
			deleteInsuranceRequest(item) {
				if (!confirm("You want to delete this Insurance request?")) return null;
				var $this = this;
				var url = this.baseUrl + `/insurance-request/${item.id}/delete`;
				return this.get(url).then(data => {
					if (data.data.length == 0) {
						$this.allInsuranceRequests = [];
					}
					data.data.forEach(data => {
						$this.allInsuranceRequests.push(data);
					});
				});
			},
			handleInput(val) {
				// console.log(val);
			}
		}
	};
</script>

<style lang="scss">
</style>
